<template v-slot:v-input>
  <div class="v-input">
    <input
      id="v-input"
      class="v-input__field"
      type="text"
      placeholder="Поиск"
      v-model="searchValue"
      @keyup.enter="handleSearch"
    >
    <button
      class="v-input__button"
      @click="handleSearch"
    >
      <img src="@/assets/image/icons/catalog-search.svg" alt="search">
    </button>
  </div>
</template>

<script>

export default {
  name: 'VInput',
  data() {
    return {
      searchValue: '',
    }
  },
  methods: {
    handleSearch(){
      this.$router.push({name: 'search', query: { text: this.searchValue }});
      this.searchValue = '';
    },
  },
}
</script>

<style scoped lang="scss">
.v-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  height: 30px;
  padding: 6px 10px;
  background-color: #444444;
  @include tablet {
    width: 100%;
    height: 36px;
  }
  &__field {
    background-color: #444444;
    color: $white;
    outline: none;
  }

  &__button {
    padding: 6px;
    font-size: 16px;
    line-height: 15px;
    cursor: pointer;
  }
}
</style>

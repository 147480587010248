<template>
  <div class="text-block">
    <p>
      Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного
      текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.
      При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами
      случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым
      для визуально-слухового восприятия.
    </p>
    <p>
      По своей сути рыбатекст является альтернативой традиционному lorem ipsum, который вызывает у некторых людей
      недоумение при попытках прочитать рыбу текст. В отличии от lorem ipsum, текст рыба на русском языке наполнит любой
      макет непонятным смыслом и придаст неповторимый колорит советских времен.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TextPageBottom',
}
</script>

<style scoped lang="scss">

.text-block {
  margin-top: 58px;
  margin-bottom: 71px;
  @include phone {
    margin: 30px 0;
  }
  & p {
    margin-bottom: 1em;
  }
}
</style>

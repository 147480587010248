import {api, fetchWithAuth} from '@/api/api';

export default {
  state: {
    profileData: {},
    promocodeData: {},
    favorites: [],
    purchases: [],
    orders: [],
  },
  getters: {
    profileData(state) {
      return state.profileData;
    },
    promocodeData(state) {
      return state.promocodeData;
    },
    favorites(state) {
      return state.favorites;
    },
    purchases(state) {
      return state.purchases;
    },
    orders(state) {
      return state.orders;
    },
  },

  mutations: {
    SET_FAVORITE: (state, favoritesData) => {
      state.favorites = favoritesData;
    },
    SET_PROFILE_DATA: (state, profileData) => {
      state.profileData = profileData;
    },
    SET_PROMOCODE_DATA: (state, promocodeData) => {
      state.promocodeData = promocodeData;
    },
    SET_PURCHASES_DATA: (state, purchasesData) => {
      state.purchases = purchasesData;
    },
    SET_ORDERS_DATA: (state, purchasesData) => {
      state.orders = purchasesData;
    },
  },

  actions: {
    GET_PROFILE_DATA({commit, state}) {
      fetchWithAuth.get('api/auth/profile')
        .then(res => {
          commit('SET_PROFILE_DATA', res.data.data)
        })
    },
    GET_PURCHASES_DATA({commit, state}) {
      fetchWithAuth.get('api/purchases')
        .then(res => {
          commit('SET_PURCHASES_DATA', res.data.data)
        })
    },
    GET_ORDERS_DATA({commit, state}) {
      fetchWithAuth.get('api/orders')
        .then(res => {
          commit('SET_ORDERS_DATA', res.data.data)
        })
    },
    GET_FAVORITE_PRODUCTS({commit}) {
      return fetchWithAuth.get('api/favorites')
        .then(favorites => {
          commit('SET_FAVORITE', favorites.data.data)
        })
        .catch(error => {
          return error;
        })
    },
    POST_PROMOCODE({commit, state}, enteredPromocode) {
      if (localStorage.getItem('access_token')) {
        fetchWithAuth.get(`api/cart/get-total/apply-promocode/${enteredPromocode}`)
          .then(res => {
            commit('SET_PROMOCODE_DATA'. res.data)
          })
      } else {
        let order_items = [];
        state.cart.forEach(el => order_items.push({id: el.good.id, unit_quantity: el.quantity, cost: el.good.price}))
        api.post('api/guest-order/apply-promocode', {
          order_items: order_items,
          promocode: enteredPromocode,
        })
          .then(res => {
            commit('SET_PROMOCODE_DATA'. res.data)
          })
      }
    },
  }
}

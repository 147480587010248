<template>
  <section class="categories">
    <div class="container categories__container">
      <h2 class="categories__title">Популярные категории сейчас</h2>
      <div class="categories__list">
        <div
          v-for="category in popularCategories"
          :key="category.id"
          class="categories__card"
          @click="$router.push({path: `/catalogue/categories/${category.id}`})"
        >
          <span class="categories__name">{{ category.name }}</span>
          <img class="categories__image" :src="category.img" :alt="category.name">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'VPopular',
  computed: {
    ...mapGetters([
      'popularCategories',
      'categories',
    ])
  },
  methods: {
    ...mapActions([
      'GET_POPULARS',
      'GET_CATEGORIES'
    ])
  },
  mounted() {
    this.GET_POPULARS();
    this.GET_CATEGORIES();
  }
}
</script>

<style scoped lang="scss">
.categories {
  margin-top: 5em;
  @include phone {
    margin-top: 1em;
  }
  &__container {
    padding: 0 15px;
  }
  &__title {
    font-size: 2.5em;
    font-weight: 400;
    margin-bottom: 36px;
    @include phone {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &__card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    width: 262px;
    height: 100px;
    margin-bottom: 1em;
    margin-right: 25px;
    background-color: $main-dark;
    border-radius: 10px;
    overflow: hidden;
    @include small-desktop {
      width: 362px;
    }
    @include tablet {
      width: 70%;
      margin-right: 0px;
    }
    @include phone {
      width: 100%;
      margin-right: 0px;
    }
 }
  &__image {
    //width: 100px;
    height: 100%;
    max-width: 100px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__name {
    margin-left: 1em;
    font-size: 24px;
    //white-space: nowrap;
    @include tablet {
      margin-left: 3em;
    }
    @include phone {
      font-size: 18px;
    }

  }
}
</style>

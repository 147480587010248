<template>
  <div id="home-page">
    <VHero>
      <TheSidebar/>
    </VHero>
    <VPopulars />
    <SliderPromotional :title="SliderPromotionalFirstTitle" />
    <VCategories />
    <VCatalog />
    <SliderPromotional :title="SliderPromotionalSecondTitle" /></div>
</template>

<script>
import VHero from '@/components/index/VHero';
import TheSidebar from '@/components/sidebar/TheSidebar';
import VCategories from '@/components/index/VCategories';
import SliderPromotional from '@/components/index/SliderPromotional';
import VPopulars from '@/components/index/VPopulars';
import VCatalog from '@/components/index/VCatalog';
import { mapActions} from 'vuex';

export default {
  name: 'HomePage',
  components: {
    TheSidebar,
    VHero,
    VPopulars,
    SliderPromotional,
    VCategories,
    VCatalog,
  },
  data() {
    return {
      SliderPromotionalFirstTitle: 'Акции',
      SliderPromotionalSecondTitle: 'Статьи',
    }
  },
  methods: {
    ...mapActions([
      'GET_PRODUCTS_CART'
    ])
  },
  mounted() {
    this.GET_PRODUCTS_CART()
  }
}
</script>


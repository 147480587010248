<template>
  <div class="order-page">
    <div class="container order-page__container">
      <div class="order-page__wrapper">
        <TheSidebar />
        <div class="order">
          <h1 class="order__title">Оформление заказа</h1>
          <div class="order__links">
            <router-link to="/">Главная</router-link>
            <span> / Оформление заказа</span>
          </div>
          <form class="order__delivery delivery" @submit.prevent="sendOrder">
            <div class="delivery__where">
              <h2 class="delivery__title">Доставка в <span>Алматы</span></h2>
              <div class="delivery__wrap" >
                <div
                  :class="{'delivery__item': true, 'delivery-way': true, 'delivery-way--active': activeDeliveryWay === 'delivery_mode'}"
                  @click="selectDeliveryWay('delivery_mode')"
                >
                  <div class="delivery-way__wrap">
                    <div class="delivery-way__title">Адресная доставка</div>
                    <p class="delivery-way__text">Куда приятнее, когда курьер доставит заказ
                      прямо к порогу квартиры или офиса!
                    </p>
                    <div class="delivery-way__address" v-if="activeDeliveryWay === 'delivery_mode'">

                      <div class="v-select">
                        <div class="v-select__wrap" @click="optionsVisible = !optionsVisible">
                          <input
                            readonly
                            class="v-select__title"
                            :placeholder="selectedCity"
                          >
                          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 3.65381L0.535898 0.423039L7.4641 0.423039L4 3.65381Z" fill="white"/>
                          </svg>
                        </div>
                        <div
                          class="v-select__options"
                          v-if="optionsVisible"
                        >
                          <p
                            class="v-select__option"
                            v-for="(city, i) in cities"
                            :key="i"
                            @click="selectOption(city)"
                          >
                            {{ city }}
                          </p>
                        </div>
                      </div>
                      <div class="whom__input-wrap v-select__input">
                        <input
                          class="whom__input"
                          type="text"
                          placeholder="Введите адес доставки"
                          v-model.trim="orderData.delivery_address"
                        >
                        <p
                          v-if="$v.orderData.delivery_address.$dirty && !$v.orderData.delivery_address.required"
                          class="whom__feedback"
                        >Обязательное поле
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="delivery-way__button">
                    <button class="delivery-way__icon point__btn">
                      <img src="@/assets/image/icons/order-home.svg">
                    </button>
                    <button class="delivery-way__label">
                      <span>Выбрать</span>
                    </button>
                  </div>
                </div>
                <div
                  :class="{'delivery__item': true, 'delivery-way': true, 'delivery-way--active': activeDeliveryWay === 'pickup_mode'}"
                  @click="selectDeliveryWay('pickup_mode')"
                >
                  <div class="delivery-way__wrap" >
                    <div class="delivery-way__title">Пункты выдачи</div>
                    <p class="delivery-way__text">Закажите товар в пункт выдачи и мы уведомим Вас о готовности
                      заказа к выдаче по e-mail или по SMS
                    </p>
                  </div>
                  <div class="delivery-way__button" >
                    <button class="delivery-way__icon point__btn">
                      <img src="@/assets/image/icons/order-point.svg">
                    </button>
                    <button class="delivery-way__label">
                      <span>Выбрать</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="delivery__whom whom">
              <div class="whom__wrapper">
                <h2 class="delivery__title whom__title">Кому доставить</h2>
                <div class="whom__tabs">
                  <div
                    :class=" {whom__tab: true, 'whom__tab--active': isActive('physical')}"
                    @click="setActiveTab('physical')"
                  >Физ. лицо</div>
                  <div
                    :class="{whom__tab: true, 'whom__tab--active': isActive('judicial')}"
                    @click="setActiveTab('judicial')"
                  >Юр.лицо/ИП</div>
                  <div
                    :class="{whom__tab: true, 'whom__tab--active': isActive('owner')}"
                    @click="setActiveTab('owner')"
                    v-if="orderWithAuth"
                  >Я получатель</div>
                </div>
                <div v-if="activeTab === 'physical'" class="whom__inputs">
                  <div class="whom__input-wrap">
                    <label class="whom__label">Фамилия и имя</label>
                    <input class="whom__input" type="text" placeholder="Михайлов Михаил" v-model.trim="orderData.full_name">
                    <p
                      v-if="$v.orderData.full_name.$dirty && !$v.orderData.full_name.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.full_name.$dirty && !$v.orderData.full_name.minLength"
                      class="whom__feedback"
                    > Не менее 2-х символов
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label" >Телефон</label>
                    <input
                      class="whom__input"
                      type="tel"
                      placeholder="7 (999) 999-99-99"
                      v-mask="'#(###)###-##-##'"
                      v-model.trim="orderData.phone_number"
                    />
                    <p
                      v-if="$v.orderData.phone_number.$dirty && !$v.orderData.phone_number.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.phone_number.$dirty && !$v.orderData.phone_number.minLength"
                      class="whom__feedback"
                    >Номер введен не полностью
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label" >Электронная почта</label>
                    <input class="whom__input" type="text" placeholder="example@gmail.com" v-model.trim="orderData.email">
                    <p
                      v-if="$v.orderData.email.$dirty && !$v.orderData.email.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.email.$dirty && !$v.orderData.email.email"
                      class="whom__feedback"
                    >Почта введена некорректно
                    </p>
                  </div>
                </div>

                <!--inputs для юр.лиц-->

                <div v-if="activeTab === 'judicial'" class="whom__inputs">
                  <div class="whom__input-wrap">
                    <label class="whom__label">Название компании</label>
                    <input class="whom__input" type="text" placeholder="Название" v-model.trim="orderData.company_title">
                    <p
                      v-if="$v.orderData.company_title.$dirty && !$v.orderData.company_title.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label" >БИН/ИНН</label>
                    <input
                      class="whom__input"
                      type="text"
                      placeholder="999999999999"
                      v-mask="'############'"
                      v-model.trim="orderData.bin"
                    />
                    <p
                      v-if="$v.orderData.bin.$dirty && !$v.orderData.bin.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.bin.$dirty && !$v.orderData.bin.minLength"
                      class="whom__feedback"
                    > Должен состоять из 12 цифр
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label">Расчетный счет</label>
                    <input
                      class="whom__input"
                      type="text"
                      placeholder="99999999999999999999"
                      v-mask="'####################'"
                      v-model.trim="orderData.account"
                    />
                    <p
                      v-if="$v.orderData.account.$dirty && !$v.orderData.account.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.account.$dirty && !$v.orderData.account.minLength"
                      class="whom__feedback"
                    > Должен состоять из 20 цифр
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label">Адрес</label>
                    <input class="whom__input" type="text" placeholder="г.Алматы, ул.Навои, зд. 204" v-model.trim="orderData.address">
                    <p
                      v-if="$v.orderData.address.$dirty &&!$v.orderData.address.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label" >Электронная почта</label>
                    <input class="whom__input" type="text" placeholder="example@gmail.com" v-model.trim="orderData.email">
                    <p
                      v-if="$v.orderData.email.$dirty && !$v.orderData.email.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.email.$dirty && !$v.orderData.email.email"
                      class="whom__feedback"
                    >Почта введена некорректно
                    </p>
                  </div>
                </div>

                <!--input я получатель-->

                <div v-if="activeTab === 'owner'" class="whom__inputs">
                  <div class="whom__input-wrap">
                    <label class="whom__label">Фамилия и имя</label>
                    <input class="whom__input" type="text" placeholder="Михайлов Михаил" v-model.trim="orderData.full_name">
                    <p
                      v-if="$v.orderData.full_name.$dirty && !$v.orderData.full_name.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.full_name.$dirty && !$v.orderData.full_name.minLength"
                      class="whom__feedback"
                    > Не менее 2-х символов
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label" >Телефон</label>
                    <input
                      class="whom__input"
                      type="tel"
                      placeholder="+7(999)999-99-99"
                      v-mask="'#(###)###-##-##'"
                      v-model.trim="orderData.phone_number"
                    />
                    <p
                      v-if="$v.orderData.phone_number.$dirty && !$v.orderData.phone_number.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.phone_number.$dirty && !$v.orderData.phone_number.minLength"
                      class="whom__feedback"
                    >Номер введен не полностью
                    </p>
                  </div>
                  <div class="whom__input-wrap">
                    <label class="whom__label" >Электронная почта</label>
                    <input class="whom__input" type="text" placeholder="m.kasanov@gmail.com"  v-model.trim="orderData.email">
                    <p
                      v-if="$v.orderData.email.$dirty && !$v.orderData.email.required"
                      class="whom__feedback"
                    >Обязательное поле
                    </p>
                    <p
                      v-if="$v.orderData.email.$dirty && !$v.orderData.email.email"
                      class="whom__feedback"
                    >Почта введена некорректно
                    </p>
                  </div>
                </div>
              </div>
              <div class="whom__agreement">
                <p>
                  Нажимая кнопку «Подтвердить заказ», вы подтверждаете выбранный способ доставки и
                  оплаты, ознакомились и согласны со всеми условиями покупки товаров на данном сайте,
                  в том числе, но не ограничиваясь, со всей информацией в разделах «Клиентам» и
                  «Помощь покупателям», принимаете <span>соглашение на обработку персональных данных</span>,
                  а также соглашаетесь на использование <span>простой электронной подписи</span>
                  при получении товара.
                </p>
              </div>
            </div>
          </form>

          <div class="order__total total">
            <div class="total__wrapper">
              <div class="total__without-discount">
                <div class="total__previously" v-if="totalPrice > 100000">
                  <span>Итого:</span>
                  <span>{{ formatPrice(isTotalPriceWithoutDiscount) }} тг</span>
                </div>
                <div  class="total__discount" v-if="totalPrice > 100000">
                  <span>Скидка:</span>
                  <span>15%</span>
                </div>
              </div>
              <div class="total__total-price">
                <span>Всего:</span>
                <span>{{ formatPrice(totalPriceWithPromocode) }} тг</span>
              </div>
              <div class="total__btn-group">
                <button class="total__btn-send" @click="sendOrder">Оформить заказ</button>
                <button class="total__btn-clear" @click="cancelOrder">Отменить заказ</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainProductSlider titleSlider='Также рекомендуем' :contentSlider="this.$store.getters.recommended" />
      <TextPageBottom />
    </div>
  </div>
</template>

<script>
import TheSidebar from "@/components/sidebar/TheSidebar";
import MainProductSlider from "@/components/common/MainProductSlider";
import TextPageBottom from "@/components/common/TextPageBottom";
import {mapActions, mapGetters} from "vuex";
import formatPrice from "@/mixins/formatPrice";
import {api, fetchWithAuth} from "@/api/api";
import { validationMixin } from 'vuelidate';
import { required, minLength, email, requiredIf, maxLength, helpers } from 'vuelidate/lib/validators';
import router from "@/router";

export default {
  name: 'OrderPage',
  mixins: [formatPrice, validationMixin],
  components: {
    TheSidebar,
    MainProductSlider,
    TextPageBottom,
  },
  props: {
    promocode: {
      type: String,
      default() {
        return null
      }
    },
  },
  computed: {
    ...mapGetters([
      'totalPrice',
      'promocodeData',
      'profileData',
      'cart',
      'orderWithAuth',
      'totalPriceWithPromocode'
    ]),
    isTotalPriceWithoutDiscount() {
      let priceWithoutDiscount = 0;
      this.cart.forEach(product => priceWithoutDiscount += (product.good.price * product.quantity));
      return priceWithoutDiscount;
    },
    isTotalPrice() {
      if(!localStorage.getItem('access_token')) {
        return this.formatPrice(this.totalPrice > 100000 ? Math.floor(this.totalPrice * 0.85) : this.totalPrice)
      } else {
        return this.formatPrice(this.totalPrice);
      }
    },
    isActiveTabDelivery() {
      return 'delivery_mode' === true.activeDeliveryWay;
    },
    isActiveTabType() {
      return 'physical' === true.activeTab;

    },
  },
  data() {
    return {
      orderError: '',
      cities: ['Алматы'],
      optionsVisible: false,
      selectedCity: 'Алматы',
      activeTab: 'physical',
      activeDeliveryWay: 'pickup_mode',
      orderData: {
        delivery_mode: false,
        pickup_mode: true,
        city: 'Алматы',
        pickup_address: 'Навои, 204',
        delivery_address: null,
        physical: true,
        full_name: '',
        phone_number: '',
        email: null,
        judicial: false,
        company_title: null,
        bin: null,
        account: null,
        address: null,
      },
    }
  },
  methods: {
    ...mapActions([
      "ADD_TO_NOTIFICATION",
      "GET_PROFILE_DATA",
      "DELETE_ALL_FROM_CART",
      "CHANGE_AUTH_STATUS",
      "GET_ORDERS_DATA",
    ]),
    cancelOrder() {
      this.orderData = {
        delivery_mode: false,
        pickup_mode: true,
        city: 'Алматы',
        pickup_address: 'Навои, 204',
        delivery_address: null,
        physical: true,
        full_name: '',
        phone_number: '',
        email: null,
        judicial: false,
        company_title: null,
        bin: null,
        account: null,
        address: null,
      };
      this.$router.push({name: 'cart'});
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.orderData.judicial = tab === 'judicial';
      this.orderData.physical = tab === 'physical';
      if (tab === 'owner') {
        this.orderData.physical = true;
        this.orderData.full_name = this.profileData.name;
        this.orderData.phone_number = this.profileData.phone_number;
        this.orderData.email = this.profileData.email;
      } else {
        this.orderData.full_name = '';
        this.orderData.phone_number = '';
        this.orderData.email = '';
      }
    },
    isActive(tab) {
      return this.activeTab === tab;
    },

    discountAfterLimit(price, limit = 100000) {
      return this.formatPrice(price > limit ? Math.ceil(price * 0.85) : price)
    },
    selectDeliveryWay (way) {
      this.activeDeliveryWay = way;
      this.orderData.pickup_mode = way === 'pickup_mode';
      this.orderData.delivery_mode = way === 'delivery_mode';
      this.orderData.pickup_mode ? this.orderData.pickup_address = 'ул. Навои, здание 204' : this.orderData.pickup_address = null;
    },
    selectOption(option) {
      if (option !== this.orderData.city) {
        this.orderData.city = option;
      }
      this.optionsVisible = false;
    },
    sendOrder() {
      this.$v.orderData.$touch();
      if (!this.$v.orderData.$error) {
        if (localStorage.getItem('access_token')) {
          fetchWithAuth.post('api/make-order', {...this.orderData, promocode: this.promocode} )
            .then(res => {
              this.ADD_TO_NOTIFICATION({message: res.data.message, timeOut: 7000});
              this.GET_ORDERS_DATA();
              this.$router.push({name: 'home'})
              setTimeout(() => {
                this.clearOrderInputs();
                this.DELETE_ALL_FROM_CART();
                this.$router.push({name: 'home'})
              }, 3000)
            })
            .catch(error => {
              this.orderError = error;
            })
        } else {
          let order_items = [];
          this.cart.forEach(el => order_items.push({id: el.good.id, unit_quantity: el.quantity, cost: el.good.price}));
          api.post('api/make-guest-order', {
            ...this.orderData,
            order_items: order_items,
            promocode: this.promocode,
          })
            .then(res => {
              this.ADD_TO_NOTIFICATION({message: res.data.message, timeOut: 7000});
              setTimeout(() => {
                this.clearOrderInputs();
                this.DELETE_ALL_FROM_CART()
                this.$router.push({name: 'home'})
              }, 3000)
            })
            .catch(error => {
              this.orderError = error;
              this.ADD_TO_NOTIFICATION({message:'К сожалению, произошла ошибка'});
            })
        }
      }
    },
    getProfileData() {
      if(this.orderWithAuth) {
        this.GET_PROFILE_DATA();
      }
    },
    clearOrderInputs() {
      this.orderError = '';
      this.activeTab = 'physical';
      this.activeDeliveryWay = 'pickup_mode';
      this.orderData = {
        delivery_mode: false,
        pickup_mode: true,
        city: 'Алматы',
        pickup_address: 'Навои, 204',
        delivery_address: null,
        physical: true,
        full_name: null,
        phone_number: '',
        email: null,
        judicial: false,
        company_title: null,
        bin: null,
        account: null,
        address: null,
      }

    }
  },
  validations: {
    orderData: {
      city: {
        required
      },
      delivery_address: {
        required: requiredIf(function () {
          return this.activeDeliveryWay === 'delivery_mode'
        })
      },
      pickup_address: {
        required: requiredIf(function () {
          return this.activeDeliveryWay === 'pickup_mode'
        })
      },

      email: {
        required,
        email,
      },
      full_name: {
        required: requiredIf(function () {
          return this.activeTab === 'physical' || this.activeTab === 'owner'
        }),
        // alpha: val => /^[а-яА-ЯёЁa-zA-Z\s]+$/.test(val),
        minLength: minLength(2),
      },
      phone_number: {
        required: requiredIf(function () {
          return this.activeTab === 'physical' || this.activeTab === 'owner'
        }),
        minLength: minLength(15),
      },
      company_title: {
        required: requiredIf(function () {
          return this.activeTab === 'judicial'
        }),
      },
      account: {
        required: requiredIf(function () {
          return this.activeTab === 'judicial'
        }),
        minLength: minLength(20),
        maxLength: maxLength(20),
      },
      bin: {
        required: requiredIf(function () {
          return this.activeTab === 'judicial'
        }),
        minLength: minLength(12),
        maxLength: maxLength(12),
      },
      address: {
        required: requiredIf(function () {
          return this.activeTab === 'judicial'
        })
      },
    },
  },
  mounted() {
    this.getProfileData();
  }
}

</script>

<style scoped lang="scss">
.order-page {
  position: relative;
  &__container {
    padding: 0 15px;
  }
  &__wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: 81px;
  }
}

.order {
  width: 100%;
  &__info {
    max-width: 314px;
  }
  &__title {
    margin-bottom: 15px;
    font-weight: 500;
  }

  &__links {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 49px;
    & span {
      color: $orange-color;
    }
  }
}
.delivery {
  &__where {
    background-color: $main-dark;
    padding: 26px;
    margin-bottom: 15px;
    border-radius: 10px;
    @include phone {
      padding: 15px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: start;
    @include tablet {
      flex-direction: column;
    }

  }
  &__item {
    display: flex;
    flex: 1;
    border: 1px solid #333333;
    border-radius: 10px;
  }
  &__title {
    margin-bottom: 18px;
    font-size: 24px;
    font-weight: 500;
    & > span {
      color: $orange-color;
    }
  }
  &__whom {
    background-color: $main-dark;
    border-radius: 10px;
  }
}

.delivery-way {
  padding: 30px;
  @include phone {
    padding: 15px;
  }
  &--active {
    border: 2px solid $orange-color;
  }
  &__address {
    width: 100%;
  }
  &__wrap {
  }
  margin-right: 10px;
  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;

  }
  &__text {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  &__button {
  }
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: 5px;
    color: $orange-color;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: $orange-color;
    border-radius: 50%;
  }
  &__label {
    color: $orange-color;
    & > span {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.whom {
  max-width: 100%;
  &__wrapper {
    padding: 30px 30px 43px 30px;
    @include phone {
      padding: 15px;
    }
  }
  &__tabs {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @include phone {
      justify-content: space-between;
    }
  }
  &__tab {
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    margin-right: 16px;
    color: $dark-grey;
    border: 2px solid $dark-grey;
    border-radius: 5px;
    white-space: nowrap;
    &--active {
      color: $main-bg;
      background-color: $orange-color;
      border: 2px solid $orange-color;
    }
    @include phone {
      padding: 5px 8px;
      margin-right: 5px;
      margin-bottom: 15px;
      flex: 1;
    }
  }
  &__feedback {
    color: #f8411e;;
    font-size: 13px;
    margin-top: 5px;
  }
  &__label {
    display: inline-block;
    margin-bottom: 14px;
    font-size: 15px;
    @include phone {
      margin-bottom: 5px;
    }
  }
  &__inputs {
    display: flex;
    //align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    @include phone {
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
    }
  }
  &__input-wrap {
    max-width: 240px;
    min-width: 212px;
    margin-right: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0;
    }
    @include phone {
      width: 100%;
      max-width: 100%;
    }
  }
  &__input {
    width: 100%;
    font-size: 13px;
    padding: 13px 0 14px 19px;
    line-height: 18px;
    color: white;
    background-color: $main-bg;
    border-radius: 5px;
    @include phone {
      margin-bottom: 15px;
    }
  }
  &__agreement {
    padding: 30px 30px 35px 30px;
    border-top: 1px solid #C4C4C4;
    & > p {
      font-size: 10px;
      line-height: 18px;
      @include phone {
        font-size: 14px;
      }
      & > span {
        color: $orange-color;
        font-weight: 500;
      }
    }
  }
}

.total {
  display: flex;
  margin-top: 57px;
  @include phone {
    width: 100%;
    margin-top: 40px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    @include phone {
      width: 100%;
      margin-left: 0;
    }
  }
  &__without-discount {
    margin-bottom: 12px;
    font-weight: 500;
  }
  &__previously {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    & > span {
      font-weight: 700;
    }
  }
  &__discount {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-weight: 500;
  }
  &__total-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;
    font-size: 18px;
    font-weight: 500;
    @include phone {
      font-size: 16px;
    }

  }
  &__btn-group {
    display: flex;
    justify-content: space-between;
    @include phone {
      flex-direction: column;
    }
  }
  &__btn-send {
    height: 50px;
    padding: 16px 30px;
    background-color: $orange-color;
    border-radius: 5px;
    @include phone {
      width: 100%;
      margin-bottom: 15px;
    }

  }
  &__btn-clear {
    height: 50px;
    padding: 16px 30px;
    margin-left: 27px;
    color: $dark-grey;
    //background-color: $main-bg;
    border: 2px solid $dark-grey;
    border-radius: 5px;
    @include phone {
      width: 100%;
      margin-left: 0;
    }
  }
}
.v-select {
  position: relative;
  display: flex;
  align-items: center;
  width: 80%;
  height: 35px;
  margin-bottom: 10px;
  background-color: #333333;
  &__options {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 10;
    width: 100%;
    background-color: #8E8E8E;
    cursor: pointer;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 5px 14px 19px;
    width: 100%;
    & > input {
      outline: none;
    }
  }
  &__title {
    width: 100%;
    font-size: 13px;
    line-height: 18px;
    color: white;
    background-color: #333333;
    border-radius: 5px;
  }
  &__option {
    margin: 8px;
    padding: 4px 0 4px 8px;
    border-bottom: 1px solid $white;
    &:last-child {
      border: none;
    }
  }
  &__input {
    width: 80%;
    max-width: 100%;
  }
}
</style>


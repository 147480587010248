<template>
  <div class="sidebar__list list-1">
    <ul class="list-1__wrapper">
      <h4 class="list-1__title">{{ title }}</h4>
      <li
        class="dropdown2 list-1__item"
        v-for="(subcategory, i) in dropDownList1"
        :key="i"
      >
        <div class="list-1__wrap">
          <router-link :to="`/catalogue/categories/${categoryId}/subcategories/${subcategory.id}`">
            <span @click="closeSubcategoryList" class="sidebar__title">{{ subcategory.name }}</span>
          </router-link>
          <svg
            @click="toggleMenuItem($event, subcategory.id, subcategory.name, subcategory.series)"
            v-if="subcategory.series.length"
            class="sidebar__arrow"
            xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" fill="black"/>
          </svg>
        </div>
      </li>
    </ul>
    <SidebarList2
      ref="seriesList"
      v-if="seriesVisible"
      :title="subcategoryTitle"
      :dropDownList2="seriesList"
      :categoryId="categoryId"
      :subcategoryId="subcategoryId"
      @closeSubcategoryList="closeSubcategoryList"
    />
  </div>
</template>

<script>
import SidebarList2 from "@/components/sidebar/SidebarList2";

export default {
  name: 'SidebarList1',
  components: {
    SidebarList2,
  },
  props: {
    dropDownList1: {
      type: Array,
    },
    title: {
      type: String,
    },
    categoryId: {
      type: Number,
    }
  },
  data() {
    return {
      seriesList: [],
      seriesVisible: false,
      subcategoryTitle: '',
      subcategoryId: null,
    }
  },
  methods: {
    toggleMenuItem(e, subcategoryId, subcategoryTitle, seriesList) {
      if (subcategoryTitle !== this.subcategoryTitle) {
        this.subcategoryId = subcategoryId;
        this.subcategoryTitle = subcategoryTitle;
        this.activeMenuItem = subcategoryTitle;
        this.seriesList = seriesList;
        this.seriesVisible = true;
      } else {
        this.seriesVisible = !this.seriesVisible;
      }
    },
    closeSubcategoryList() {
      this.$emit('closeSubcategoryList')
    },
  },
  watch: {
    dropDownList1() {
      this.seriesVisible = false;
    },
  },

}

</script>

<style scoped lang="scss">
.list-1 {
  position: absolute;
  top: 4px;
  left: 189px;
  z-index: 99;
  width: 240px;
  padding: 24px 14px 24px 24px;
  //margin-bottom: 100px;
  font-size: 14px;
  line-height: 15px;
  color: #333333;
  background-color: $white;
  border-radius: 20px;
  //max-height: 500px;
  //height: 400px;
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & span {
    display: block;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    padding-right: 10px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
  }
  &__item {
    padding: 16px 0;
    margin-bottom: 1px;
    border-bottom: 1px solid #EAEAEA;
    &:first-child {
      font-size: 18px;
      font-weight: bold;
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
}

.dropdown1 {
  position: relative;
  &:hover .list-1 {
    display: initial;
  }
}

.dropdown2 {
  position: relative;
  &:hover .list-2 {
    display: initial;
  }
}
</style>

<template>
  <section class="account-page">
    <div class="container account-page__container">
      <h1 class="account-page__title">Личный кабинет</h1>
      <div class="account-page__links">
        <router-link to="/">Главная</router-link>
        <span> / Личный кабинет</span>
      </div>
        <div class="account-page__wrapper account">
          <div class="account__nav account-nav">
            <div class="account-nav__wrapper">
              <a class="account-nav__link" href="#user">
                <div
                  :class="{'account-nav__item': true, 'account-nav__item--active': activeNavItemMenu === 'user'}"
                  @click="activeNavItemMenu='user'"
                >
                  <div class="account-nav__wrap">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.3334 30.3333V27.9999C23.3334 26.7622 22.8417 25.5753 21.9666 24.7001C21.0914 23.8249 19.9044 23.3333 18.6667 23.3333H9.33341C8.09574 23.3333 6.90875 23.8249 6.03358 24.7001C5.15841 25.5753 4.66675 26.7622 4.66675 27.9999V30.3333" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.9999 18.6666C16.5772 18.6666 18.6666 16.5772 18.6666 13.9999C18.6666 11.4226 16.5772 9.33325 13.9999 9.33325C11.4226 9.33325 9.33325 11.4226 9.33325 13.9999C9.33325 16.5772 11.4226 18.6666 13.9999 18.6666Z" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Личная информация</span>
                    <div class="account-nav__item--bonuses"> {{profileData.bonus}} <span class="bonuses__item"> бонусов</span></div>
                  </div>
                </div>
              </a>
              <a class="account-nav__link" href="#bonuses">
                <div
                  :class="{'account-nav__item': true, 'account-nav__item--active': activeNavItemMenu === 'bonuses'}"
                  @click="activeNavItemMenu='bonuses'"
                >
                  <div class="account-nav__wrap">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.5 9.39996L7.5 4.20996" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.27002 6.95996L12 12.01L20.73 6.95996" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 22.08V12" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Бонусы</span>
                  </div>
                </div>
              </a>
              <a class="account-nav__link" href="#purchases">
                <div
                  :class="{'account-nav__item': true, 'account-nav__item--active': activeNavItemMenu === 'purchases'}"
                  @click="activeNavItemMenu='purchases'"
                >
                  <div class="account-nav__wrap">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1 10H23" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Покупки</span>
                  </div>
                </div>
              </a>
              <a class="account-nav__link" href="#password">
                <div
                  :class="{'account-nav__item': true, 'account-nav__item--active': activeNavItemMenu === 'password'}"
                  @click="activeNavItemMenu='password'"
                >
                  <div class="account-nav__wrap">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Изменить пароль</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="account__info account-info" id="user">
            <div class="account-info__details details">
              <h2 class="account-page__title details__title">Детали аккаунта</h2>
              <div class="details__item">
                <input
                  class="details__input"
                  type="text"
                  placeholder="Имя"
                  v-model="profileData.name"
                  readonly
                >
              </div>
              <div class="details__item">
                <input
                  class="details__input" type="text"
                  placeholder="Фамилия"
                  v-model="profileData.surname"
                  readonly
                >
              </div>
              <div class="details__item">
                <input
                  class="details__input" type="text"
                  placeholder="Адрес по умолчанию"
                  v-model="profileData.delivery_address"
                  readonly
                >
              </div>
              <div class="details__item" v-for="(address, i) in profileData.other_addresses" :key="i">
                <input
                  class="details__input" type="text"
                  placeholder="Дополнительный адрес"
                  v-model="profileData.other_addresses[i]"
                  readonly
                >
              </div>
              <div class="details__item" v-if="showInputForNewAddress">
                <input
                  class="details__input"
                  type="text"
                  placeholder="Введите новый адрес"
                  v-model="newAddressInAccount"
                  ref="info"
                >
              </div>
              <div class="details__add-address">
                <button class="details__add-btn" @click="addNewAddress">+</button>
                <span class="details__add-label">Добавить еще адрес</span>
              </div>
              <div class="details__buttons">
                <button class="account__button account__button--orange" :disabled="saveButton" @click="sendNewProfileData">Сохранить</button>
                <button
                  class="account__button details__button"
                  @click="changeProfileData"
                >
                  Изменить
                </button>
              </div>
            </div>

            <div class="account-info__orders orders">
              <div class="orders__header">
                <h2 class="account-page__title orders__title">Мои Заказы</h2>
                <span class="orders__total">{{paginatedOrders[1].total_sum}}</span>
              </div>
              <div v-if="!orders.length">Заказов пока нет</div>
              <div class="orders__wrap" v-else >
                <div class="orders__list">
                  <div
                    class="orders__list-item"
                    v-for="(order, i) in paginatedOrders"
                    :key="i"
                  >
                    <div class="item-line__date">Номер заказа: <b>{{ order.id }}</b></div>
                    <div v-for="(item, i) in order.goods"
                    :key="i">
                      <div class="orders__item item-line item-line--top">
                        <div class="item-line__name">{{ item.name }}</div>
                        <div class="item-line__wrap">
                          <div class="item-line__status">{{ order.status }}</div>
                          <div class="item-line__price">{{ !profileData.is_wholesale ? formatPrice(item.price) : formatPrice(item.wholesale_price) }} тг </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-line">
                      <div class="item-line__date">{{ order.date }}</div>
                      <div class="item-line__confirmation">
                        {{ order.confirmation_message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pages" id="purchases">
                  <div class="pages__line">
                    <div
                      class="page__block"
                      v-for="page in pages"
                      :key="page"
                      @click="pageClick(page)"
                    >
                      <div
                        class="page"
                        :class="{'page__selected': page === pageNumber}"
                        v-if="page <= pageNumber+1 && page >= pageNumber-1">
                        {{page}}
                      </div>
                    </div>
                    <div
                      v-if="pageNumber < pages"
                      class="page"
                    >
                      ...
                    </div>
                    <button
                      class="page page__arrow"
                      @click="pageForward">
                      <img :src="require('/src/assets/image/icons/arrow-pagination.svg')">
                    </button>
                  </div>
                </div>
              </div>
              </div>

            <div class="account-info__purchase purchase">
              <h2 class="account-page__title purchase__title">Покупки</h2>
              <div v-if="!purchases.length">Покупок пока нет</div>
              <div class="purchase__table" v-else>
                <div class="purchase__header">
                  <span class="purchase__header-item">Фото</span>
                  <span class="purchase__header-item">Название</span>
                  <span class="purchase__header-item">Цена</span>
                  <span class="purchase__header-item">Количество</span>
                </div>
                <div
                  class="purchase__body"
                  v-for="product in purchases"
                  :key="product.good.id"
                >
                  <div class="purchase__row">
                    <div class="purchase__row-item">
                      <img :src="product.good.photos[0]">
                    </div>
                    <span class="purchase__row-item">Длинное название в две строки</span>
                    <span class="purchase__row-item price">{{ product.good.price }} тг</span>
                    <span class="purchase__row-item">{{ product.quantity}} шт</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="account-info__favorites">
              <h2 class="account-page__title favorites__title">Избранное</h2>
              <FavoritesList :favorites="favorites" v-if="favorites.length"/>
              <div v-else>Избранных товаров пока нет</div>
            </div>
            <div class="account-info__password password-change" id="password">
              <h2>Изменить пароль</h2>
              <div class="password-change__wrap">



                <div class="details__item">
                  <input
                    v-show="!showPass"
                    class="details__input password-change__input"
                    type="password"
                    placeholder="Пароль"
                    v-model.trim="changePasswordData.current_password"
                    @input="messageAttemptPasswordChange = ''; errorPasswordChange.current_password[0]=''"
                  >
                  <input
                    v-show="showPass"
                    class="details__input password-change__inpu"
                    type="text"
                    v-model.trim="changePasswordData.current_password"
                    placeholder="Пароль"
                    @input="messageAttemptPasswordChange = ''; errorPasswordChange.current_password[0]=''"
                  />
                  <img
                    class="password-change__show-password"
                    src="@/assets/image/icons/show-password.svg"
                    @click="showPassword($event,'showPass')"
                  >
                </div>
                <div class="password-change__error">{{errorPasswordChange.current_password ? errorPasswordChange.current_password[0]: ''}}</div>

                <div class="details__item">
                  <input
                    v-show="!showNewPass"
                    class="details__input password-change__input"
                    type="password"
                    placeholder="Новый пароль"
                    v-model.trim="changePasswordData.new_password"
                    @input="messageAttemptPasswordChange = ''; errorPasswordChange.new_password[0]=''; errorPasswordChange.confirmed_new_password[0]=''"
                  >
                  <input
                    v-show="showNewPass"
                    class="details__input password-change__input"
                    type="text"
                    v-model.trim="changePasswordData.new_password"
                    placeholder="Новый пароль"
                    @input="messageAttemptPasswordChange = ''; errorPasswordChange.new_password[0]=''; errorPasswordChange.confirmed_new_password[0]=''"
                  />
                  <img
                    class="password-change__show-password"
                    src="@/assets/image/icons/show-password.svg"
                    @click="showPassword($event,'showNewPass')"
                  >
                </div>
                <div class="password-change__error">{{errorPasswordChange.new_password ? errorPasswordChange.new_password[0]: ''}}</div>
                <div class="details__item">
                  <input
                    v-show="!showNewConfirmPass"
                    class="details__input password-change__input"
                    type="password"
                    placeholder="Еще раз новый пароль"
                    v-model.trim="changePasswordData.confirmed_new_password"
                    @input="messageAttemptPasswordChange = ''; errorPasswordChange.confirmed_new_password[0]=''; errorPasswordChange.new_password[0]=''"
                  >
                  <input
                    v-show="showNewConfirmPass"
                    class="details__input password-change__input"
                    type="text"
                    v-model.trim="changePasswordData.confirmed_new_password"
                    placeholder="Еще раз новый пароль"
                    @input="messageAttemptPasswordChange = ''; errorPasswordChange.confirmed_new_password[0]=''; errorPasswordChange.new_password[0]=''"
                  />
                  <img
                    class="password-change__show-password"
                    src="@/assets/image/icons/show-password.svg"
                    @click="showPassword($event,'showNewConfirmPass')"
                  >
                </div>
                <div class="password-change__error">{{errorPasswordChange.confirmed_new_password ? errorPasswordChange.confirmed_new_password[0] : ''}}</div>
                <div class="password-change__save">
                  <button
                    class="account__button account__button--orange"
                    @click="sendPasswordForChangePassword"
                  >
                    Сохранить
                  </button>
                  <span class="password-change__message">{{messageAttemptPasswordChange}}</span>
                </div>
              </div>
            </div>

            <button
              class="account__button account__button--out"
              @click="logoutOfAccount"
            >
              Выйти
            </button>

          </div>
        </div>
      <MainProductSlider titleSlider='Также рекомендуем' :contentSlider="this.$store.getters.recommended" />
    </div>
  </section>
</template>

<script>
import { fetchWithAuth } from "@/api/api";
import { mapGetters, mapActions } from 'vuex'
import MainProductSlider from "@/components/common/MainProductSlider";
import FavoritesList from "@/components/order/FavoritesList";
import formatPrice from "@/mixins/formatPrice";
import showPassword from "@/mixins/showPassword";

export default {
  name: 'AccountPage',
  components: {
    FavoritesList,
    MainProductSlider,
  },
  mixins: [formatPrice, showPassword],
  data() {
    return {
      changePasswordData: {
        current_password: '',
        new_password: '',
        confirmed_new_password: '',
      },
      messageAttemptPasswordChange: '',
      errorPasswordChange: {
        current_password: [""],
        new_password: [ "" ],
        confirmed_new_password: [ "" ],
      },
      newAddressInAccount: '',
      showInputForNewAddress: false,
      userPerPage: 5,
      pageNumber: 1,
      saveButton: true,
      activeNavItemMenu: 'user',
      showPass: false,
      showNewPass: false,
      showNewConfirmPass: false,
    }
  },
  computed: {
    ...mapGetters([
      'cart',
      'profileData',
      'favorites',
      'purchases',
      'orders',
    ]),
    pages() {
      return Math.ceil(this.orders.length / this.userPerPage);
    },
    paginatedOrders() {
      let from = (this.pageNumber - 1) * this.userPerPage;
      let to = from + this.userPerPage;
      let ordersRevert = [...this.orders];
      return ordersRevert.reverse().slice(from, to)
    },
  },
  methods: {
    ...mapActions([
      'GET_PRODUCTS_CART',
      'ADD_TO_NOTIFICATION',
      'GET_PROFILE_DATA',
      'GET_FAVORITE_PRODUCTS',
      'GET_ORDERS_DATA',
    ]),
    pageClick(page) {
      this.pageNumber = page;
    },
    pageForward() {
      if (this.pageNumber < this.pages) {
        this.pageNumber ++;
      }
    },
    addNewAddress() {
      this.showInputForNewAddress = true;
      this.saveButton = false;
    },
    sendPasswordForChangePassword() {
      fetchWithAuth.post('api/auth/enter-changed-password', this.changePasswordData)
      .then(res => {
        if (res.data.message) {
          this.messageAttemptPasswordChange = res.data.message;
          this.changePasswordData.current_password = '';
          this.changePasswordData.new_password = '';
          this.changePasswordData.confirmed_new_password = '';
        }

        if(res.data.error) {
          this.messageAttemptPasswordChange = res.data.error;
        }
      })
      .catch(error => {
        if (error.errors) {
          this.errorPasswordChange = error.errors;
        }
      })
    },
    logoutOfAccount() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      this.GET_PRODUCTS_CART()
      this.$router.push({name: 'home'});
      this.$store.commit('CHANGE_AUTH_STATUS', false);
    },

    sendNewProfileData() {
      if (this.newAddressInAccount) {
        fetchWithAuth.put('api/auth/profile/add-address', {instance: this.newAddressInAccount})
          .then(res => {
            this.ADD_TO_NOTIFICATION({message: res.data.message});
            this.GET_PROFILE_DATA();
          })
          .catch(err => {
            this.ADD_TO_NOTIFICATION({message: 'Что-то пошло не так, попробуйте позже'})
          })
        this.showInputForNewAddress = false;
      }
      fetchWithAuth.put(`api/auth/profile`, {
        name: this.profileData.name,
        surname: this.profileData.surname,
        email: this.profileData.email,
        phone_number: this.profileData.phone_number,
        delivery_address: this.profileData.delivery_address,
        other_addresses: [],
      }).then(res => {
        const inputs = this.$el.querySelectorAll(['.details__input']);
        inputs.forEach(el => {
          el.readOnly = true;
          el.style.backgroundColor = 'transparent';
        })
        this.saveButton = true;
        this.ADD_TO_NOTIFICATION({message: 'Данные были изменены'})
      })
      this.saveButton = true;
    },
    changeProfileData() {
      const inputs = this.$el.querySelectorAll(['.details__input']);
      this.saveButton = false;
      inputs.forEach(el => {
        el.readOnly = false
        el.style.backgroundColor = 'rgb(196 196 196 / 14%)'
      })
    },
  },
  mounted() {
    this.GET_PRODUCTS_CART();
    this.GET_FAVORITE_PRODUCTS();
    this.GET_PROFILE_DATA();
    this.GET_ORDERS_DATA();
  }
}
</script>

<style lang="scss" scoped>
.account-page {
  &__container {
    padding: 0 15px;
  }
}
.account-nav {
  margin-right: 60px;
  max-width: 400px;
  @include phone {
    margin-right: 0;
    width: 100%;
  }
  &__wrapper {
    background-color: $main-dark;
    border-radius: 10px;
    @include phone {
      //padding: 0 25px;
    }
  }
  &__link {
    display: block;
    &:last-child {
      & .account-nav__item {
        & .account-nav__wrap {
          border-bottom: none;
        }
      }
    }

  }
  &__item {
    padding: 0  33px 0 42px;
    height: 84px;
    font-size: 18px;
    border-radius: 10px;
    @include phone {
      font-size: 16px;
      padding: 0px 15px 0 15px;
    }
    //& > span {
    //  white-space: nowrap;
    //}
    //& > svg {
    //  margin-right: 20px;
    //}
    &--bonuses {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 900;
      margin-left: 10px;
      padding: 8px 13px;
      color: #323232;
      background-color: #FFE9C6;
      border-radius: 10px;
      & .bonuses__item {
        font-weight: 400;
      }
      & > span {
        margin-left: 5px;
      }
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #F4F2DD;
    & > span {
      white-space: nowrap;
    }
    & > svg {
      margin-right: 20px;
    }
  }
  &__item--active {
    background-color: #010000;

    color: $orange-color;
    & .account-nav__wrap {
      border-bottom: none;
    }
  }
  //&__item--active ~ .account-nav__item {
  //  & > .account-nav__wrap {
  //    border-top: none;
  //  }
  //}
}

.account-info {
  width: 100%;
  @include tablet {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.account-page {
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
  }
  &__links {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 49px;
    & span {
      color: $orange-color;
    }
  }
}
.account {
  display: flex;
  align-items: stretch;
  //justify-content: space-between;
  @include tablet {
    flex-direction: column;
  }
  &__title {
  }
  &__button {
    max-width: 210px;
    padding: 17px 56px;
    font-size: 17px;
    font-weight: 500;
    background-color: white;
    border-radius: 10px;
    @include phone {
      min-width: 100%;
      font-size: 16px;
    }
    &--out {
      min-width: 195px;
      @include phone {
        min-width: 100%;
      }
    }
    &--orange {
      background-color: $orange-color;
      margin-right: 30px;
      @include phone {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }
}
.favorites {
  &__title {
    margin-top: 50px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  &__title {
    @include phone {
      font-size: 16px;
    }
  }
  &__item {
    position: relative;
    border-bottom: 1px solid #C4C4C436;
    padding-top: 32px;
    padding-bottom: 9px;
  }
  &__input {
    display: inline-block;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    color: white;
    background-color: transparent;
    outline: none;
  }
  &__add-address {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 38px;
  }
  &__add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 20px;
    font-size: 21px;
    background-color: $orange-color;
    border-radius: 50%;
  }
  &__add-label {
    font-size: 15px;
    color: #737373;
  }
  &__buttons {
    margin-bottom: 30px;
    @include phone {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
    }
  }
}

.orders{
  width: 100%; //!!
  margin-bottom: 46px;
  //&__wrap {
  //  height: 470px;
  //}
  &__title {
  }
  &__list {
    border: 1px solid #D0D0D0;
    border-radius: 10px;
  }
  &__list-item {
    padding: 23px 35px;
    border-bottom: 1px solid #D0D0D0;
    // height: 94px;
    //&:last-child {
    //  border-bottom: none;
    //}
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__total {
    font-size: 24px;
    font-weight: 700;
    color: $orange-color;
  }
}
.item-line {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: white;
  &--top {
    position: relative;
    font-size: 15px;
    color: $orange-color;
    margin-bottom: 5px;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
  }
  &__status {
    position: absolute;
    right: 18%;
  }
}

.purchase {
  width: 100%;  //!!
  font-size: 15px;
  &__table {
    //width: 700px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    border-bottom: 1px solid #C4C4C436;
    &-item {
      flex: 1;
      display: flex;
      justify-content: center;
      &:first-child {
        justify-content: left;
      }
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 0;
    border-bottom: 1px solid $grey-line;
    width: 100%;
    &-item {
      flex: 1;
      display: flex;
      justify-content: center;
      &:first-child {
        justify-content: left;
      }
      & img {
        width: 90px;
        height: 60px;
      }
      &.price {
        color: $orange-color;
        font-weight: 700;
      }
    }
  }
}

.password-change {
  margin-top: 45px;
  &__wrap {
    display: flex;
    flex-direction: column;
  }
  &__input {
    max-width: 500px;
  }
  &__error {
    color: #e32526;
    font-size: 13px;
    margin-top: 10px;
  }
  &__message {
    color: white;
    font-size: 17px;
    font-weight: 700;
  }
  &__save {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 60px;
    @include phone {
      flex-direction: column;
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
  &__btn {
    margin-top: 50px;
  }
  &__show-password.active {
    &::after {
      opacity: 0;
    }
  }
  &__show-password {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 30px;
    right: 25px;
    &::after {
      position: absolute;
      z-index: 100;
      content: '';
      top: 10px;
      right: 0;
      width: 25px;
      height: 2px;
      background-color: $grey-line;
      transform: rotate(45deg);
      opacity: 1;
    }
  }
}
.pages {
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
  &__line {
    display: flex;
  }
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  color: black;
  background-color: #EBEBF4;
  border-radius: 6px;
  &__arrow {
    border-radius: 50%;
  }
}
.page__selected{
  background-color: $orange-color;
}
</style>


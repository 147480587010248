import {fetchWithAuth} from "@/api/api";
import {mapActions, mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters([
      'cart',
      'notification'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_FAVORITE_PRODUCTS',
      'ADD_TO_NOTIFICATION',
    ]),
    addToFavorite(id) {
      if (localStorage.getItem('access_token')) {
        fetchWithAuth.post(`https://taro.a-lux.dev/api/catalogue/${id}/add-to-favorites`)
          .then(res => {
            this.GET_FAVORITE_PRODUCTS()
            this.ADD_TO_NOTIFICATION({message: res.data.message});
          })
      } else {
        this.$store.commit('SHOW_FORM_LOGIN')
      }
    }
  }
}

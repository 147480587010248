<template v-slot:v-select>
  <div class="v-select">
    <div class="v-select__title" @click="optionsVisible = !optionsVisible">
      <p>
        Порядок: {{ selectedOption}}
      </p>
      <img class="v-select__icon" src="@/assets/image/icons/catalog-sort.svg" alt="sort">
    </div>
    <div
      class="v-select__options"
      v-if="optionsVisible"
    >
      <p
        class="v-select__option"
        v-for="(option, i) in options"
        :key="i"
        @click="selectOption(option)"
      >
        {{ option.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  data() {
    return {
      options: [
        {
          name: 'По умолчанию',
          value: 'default',
        },
        {
          name: 'По увеличению цены',
          value: 'asc',
        },
        {
          name: 'По уменьшению цены',
          value: 'decr',
        },
      ],
      optionsVisible: false,
      selectedOption: 'По умолчанию',
    }
  },
  methods: {
    selectOption(option) {
      if (option.name !== this.selectedOption) {
        this.selectedOption = option.name;
        this.$emit('sort', option.value);
        this.optionsVisible = false;
      } else {
        this.optionsVisible = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.v-select {
  position: relative;
  display: flex;
  align-items: center;
  width: 210px;
  height: 30px;
  background-color: #444444;
  @include tablet {
    width: 100%;
    height: 36px;
  }
  &__options {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 10;
    width: 100%;
    background-color: #8E8E8E;
    cursor: pointer;
  }
  &__icon {
    padding: 6px 5px;
    font-size: 16px;
    line-height: 15px;
    cursor: pointer;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    & > p {
      white-space: nowrap;
    }
  }
  &__option {
    margin: 8px;
    padding: 8px 0 8px 8px;
    border-bottom: 1px solid $white;
    &:last-child {
      border: none;
    }
  }
}
</style>

export default {
  state: {
    showFeedbackModal: false,
    formTitle: 'Записаться на прием',
    inputForQuestion: false,
    inputForPhone: true,
    productIdForNotificationOfArrival: null,
  },
  getters: {
    showFeedbackModal(state) {
      return state.showFeedbackModal;
    },
    formTitle(state) {
      return state.formTitle;
    },
    inputForQuestion(state) {
      return state.inputForQuestion;
    },
    inputForPhone(state) {
      return state.inputForPhone;
    },
    productIdForNotificationOfArrival(state) {
      return state.productIdForNotificationOfArrival;
    }
  },

  mutations: {
    SHOW_FEEDBACK_MODAL: (state, {formTitle, inputForQuestion, inputForPhone, productId=null}) => {
      state.formTitle = formTitle;
      state.inputForQuestion = inputForQuestion;
      state.inputForPhone = inputForPhone;
      state.showFeedbackModal = true;
      state.productIdForNotificationOfArrival = productId;
    },
    HIDE_FEEDBACK_MODAL: (state) => {
      state.showFeedbackModal = false;
    }
  }
}

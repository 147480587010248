<template>
  <div class="sidebar__list list-2">
    <ul class="list-2__wrapper">
      <h4 class="list-2__title">{{ title }}</h4>
      <li
        class="dropdown2 list-2__item"
        v-for="(series, i) in dropDownList2"
        :key="i"
      >
        <router-link :to="`/catalogue/categories/${categoryId}/subcategories/${subcategoryId}/series/${series.id}`">
          <span @click="closeSubcategoryList" class="sidebar__title">{{ series.name }}</span>
        </router-link>
<!--        <SidebarList3-->
<!--          v-if="series.subseries.length"-->
<!--          :title="subseries.name"-->
<!--          :dropDownList3="series.subseries"-->
<!--          :categoryId="categoryId"-->
<!--          :subcategoryId="subcategoryId"-->
<!--          :seriesId="series.id"-->
<!--        />-->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SidebarList2',
  props: {
    dropDownList2: {
      type: Array,
    },
    title: {
      type: String,
    },
    categoryId: {
      type: Number,
    },
    subcategoryId: {
      type: Number,
    }
  },
  methods: {
    closeSubcategoryList() {
      this.$emit('closeSubcategoryList')
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  font-size: 16px;
  &__icon {
    margin-right: 16px;
  }
}

.list-2 {
  position: absolute;
  top: 60px;
  left: 240px;
  z-index: 99;
  width: 240px;
  padding: 24px;
  font-size: 14px;
  line-height: 15px;
  color: #333333;
  background-color: $white;
  border-radius: 20px;
  & span {
    display: block;
    padding-bottom: 16px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
  }
  &__item {
    margin-top: 16px;
    border-bottom: 2px solid #EAEAEA;
    &:first-child {
      font-size: 18px;
      font-weight: bold;
    }
    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }
}
.dropdown1 {
  position: relative;
  &:hover .list-1 {
    display: initial;
  }
}
.dropdown2 {
  position: relative;
  &:hover .list-2 {
    display: initial;
  }
}
</style>

<template>
  <div class="action-page">
    <div class="container action-page__container">
      <div class="action-page__wrapper">
        <TheSidebar/>
        <main class="action">
          <div class="action__links">
            <router-link to="/">Главная</router-link>
            <span> / {{ path === 'actions' ? 'Акции' : 'Статьи'}} </span>
          </div>
            <h1 class="action__title">{{ action.name }}</h1>
            <img class="action__img" :src="action.img">
            <p class="action__text">
              {{ action.description }}
            </p>
        </main>
      </div>
      <MainProductSlider titleSlider='Также рекомендуем' :contentSlider="recommended" />
    </div>
  </div>
</template>

<script>
import TheSidebar from '@/components/sidebar/TheSidebar';
import MainProductSlider from '@/components/common/MainProductSlider';
import { mapGetters } from 'vuex';
import {api} from '@/api/api';

export default {
  name: 'ActionPage',
  components: {
    TheSidebar,
    MainProductSlider,
  },
  props: {
    actionProps: {
      type: Object,
      default() {
        return {}
      }
    },
    titlePage: {
      type: String,
      default() {
        return ''
      }
    },
  },
  data() {
    return {
      actionData: [],
    }
  },
  computed: {
    ...mapGetters([
      'recommended'
    ]),
    action() {
      return Object.keys(this.actionProps).length ? this.actionProps : this.actionData;
    },
    path() {
      return this.$route.name
    },
  },
  methods: {
    getActionData() {
      if (!Object.keys(this.actionProps).length) {
        api(`/api/actions/${this.$route.params.id}`)
          .then(res => {
            this.actionData = res.data.data;
          })
      }
    },
  },
   mounted() {
    this.getActionData();
  },
}
</script>

<style scoped lang="scss">
.action-page {
  &__container {
    padding: 0 15px;
  }
  &__wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: 81px;
  }
}
.action {
  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 22px;
  }
  &__links {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 29px;
    & span {
      color: $orange-color;
    }
  }
  &__img {
    width: 100%;
    max-height: 420px;
    margin-bottom: 29px;
    border-radius: 10px;
  }
  &__text {
    font-size: 16px;
    line-height: 25px;
  }
}
</style>


import { api } from "@/api/api";

export default {
  state: {
    header: [],
  },

  getters: {
    header(state) {
      return state.header;
    },
  },

  mutations: {
    SET_HEADER_DATA: (state, header) => {
      state.header = header[0];
    },
  },

  actions: {
    GET_HEADER_DATA({commit, state}) {
      return api.get('api/headers')
        .then(header => {
          commit('SET_HEADER_DATA', header.data.data);
          return header;
        })
        .catch(error => {
          return error;
        })
    },
  }
}

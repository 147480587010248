<template>
  <div class="catalog-page">
    <div class="container catalog-page__container">
      <div class="catalog-page__wrapper">
        <div>
          <TheSidebar/>
        </div>
        <div class="catalog-page__products">
          <VProductsList :products="products" />
          <PreLoader v-if="loadingStatus" />
          <div class="total__goods" v-show="emptyStatus"> <span>Временно в данной категории нет продуктов</span></div>
        </div>
      </div>

      <div class="catalog-page__recommend recommend">
        <div class="recommend__buttons">
          <button
            :class="{recommend__button: true, 'active-button': isActive('recommended')}"
            @click="setActiveTab('recommended')"
          >
            Рекомендуем
          </button>
          <button
            :class="{recommend__button: true, 'active-button': isActive('discounted')}"
            @click="setActiveTab('discounted')"
          >
            Скидки
          </button>
          <button
            :class="{recommend__button: true, 'active-button': isActive('super-price')}"
            @click="setActiveTab('super-price')"
          >
            Суперцены
          </button>
          <button
            :class="{recommend__button: true, 'active-button': isActive('special-offer')}"
            @click="setActiveTab('special-offer')"
          >
            Суперпредложения
          </button>
        </div>
        <MainProductSlider v-show="activeTab === 'recommended'" :contentSlider="recommended" />
        <MainProductSlider v-show="activeTab === 'discounted'" :contentSlider="discounted" />
        <MainProductSlider v-show="this.activeTab === 'super-price'"  :contentSlider="superPrice" />
        <MainProductSlider v-show="this.activeTab === 'special-offer'" :contentSlider="specialOffer" />
        <TextPageBottom />
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from "@/components/sidebar/TheSidebar";
import VProductsList from "@/components/catalogue/VProductsList";
import MainProductSlider from "@/components/common/MainProductSlider";
import TextPageBottom from "@/components/common/TextPageBottom";
import { mapGetters } from "vuex";
import {api} from "@/api/api";
import PreLoader from "@/components/common/PreLoader";


export default {
  name: 'ProductsListPage',
  components: {
    PreLoader,
    TheSidebar,
    VProductsList,
    MainProductSlider,
    TextPageBottom,
  },
  props: [
    'categories',
    'categoryID',
    'subcategories',
    'subcategoryID',
    'series',
    'seriesID',
  ],
  data() {
    return {
      activeTab: 'recommended',
      products: [],
      loadingStatus: false,
      emptyStatus: false,
    }
  },
  computed: {
    ...mapGetters([
      'recommended',
      'discounted',
      'superPrice',
      'specialOffer',
    ]),
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    getProductsByCategory() {
      this.loadingStatus = true;
      this.emptyStatus = false;
      this.products = [];
      api.get(`api${(this.categories) ? '/'+this.categories+ '/' + this.categoryID : ''}${(this.subcategories) ? '/'+this.subcategories + '/' + this.subcategoryID : ''}${(this.series) ? '/'+this.series + '/' + this.seriesID : ''}/catalogue`)
        .then(response => {
          this.products = response.data.data;
          (!response.data.data.length) ? this.emptyStatus = true : this.emptyStatus = false;
        })
        .catch(error => {
          console.log(error);
          return error;
        })
      .finally(() => {
        this.loadingStatus = false;
      })
    },
  },
  watch: {
    categoryID() {
      this.getProductsByCategory()
    },
    subcategoryID() {
      this.getProductsByCategory()
    },
  },
  mounted() {
    this.getProductsByCategory()
  },
}
</script>

<style lang="scss">
.catalog-page {
  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 78px;
  }
  &__container {
    padding: 0 15px;
  }
  &__products {
    width: 100%;
  }
}
.recommend {
  &__buttons {
    display: flex;
  }
  &__button {
    color: white;
    margin-right: 15px;
    @include phone {
      font-size: 12px;
    }
  }
}
.active-button {
  border-bottom: 2px solid $orange-color;
}
</style>

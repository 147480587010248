<template>
  <div class="side">
    <div class="sidebar__wrap" ref='sidebarWrapper' v-if="toggleDesktopSidebar">
      <aside class="sidebar" ref="sidebarAside">
        <ul class="sidebar__categories categories" ref="categories">
          <li
            :class="{categories__item: true, 'categories__item--active': activeMenuItem === category.name}"
            v-for="(category, i) in categories"
            :key="i"
          >
            <div class="sidebar__inner categories__inner">
              <div class="categories__link" @click="hideSidebar(category.name)">
                <router-link :to="`/catalogue/categories/${category.id}`">
                  <!--                <img-->
                  <!--                  class="sidebar__icon"-->
                  <!--                  :src="category.img"-->
                  <!--                  :alt="category.icon"-->
                  <!--                >-->
                  <span>{{ category.name }}</span>
                </router-link>
              </div>
              <!--            <svg-->
              <!--              ref="arrowInCategories"-->
              <!--              class="sidebar__arrow"-->
              <!--              @click="toggleMenuItem($event, category.id, category.name, category.subcategories)"-->
              <!--              v-if="category.subcategories.length"-->
              <!--              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>-->
              <!--            </svg>-->
              <svg
                ref="arrowInCategories"
                class="sidebar__arrow"
                @click="toggleMenuItem($event, category.id, category.name, category.subcategories)"
                v-if="category.subcategories.length"
                width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L4.29135 6.29724C4.69549 5.84257 4.69549 5.15743 4.29135 4.70276L1 1" stroke-linecap="round"/>
              </svg>
            </div>
          </li>
        </ul>
      </aside>
      <SidebarList1
        ref="subcategorisList"
        v-if="subcategoriesVisible"
        :dropDownList1="subcategoriesList"
        :title="categoryTitle"
        :categoryId="categoryId"
        @closeSubcategoryList="closeSubcategoryList"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SidebarList1 from '@/components/sidebar/SidebarList1';

export default {
  name: 'TheSidebar',
  components: {
    SidebarList1,
  },
  data() {
    return {
      categoryId: null,
      categoryTitle: '',
      subcategoriesList: [],
      subcategoriesVisible: false,




      activeMenuItem: '',
      currenSelectedCategoriesItem: '',
      currenSelectedSubcategoriesItem: '',
      test: '',
      testCategories: [
        {
          id: 15266,
          name: "Accusamus ebebe vebebve.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Все для изготовления тортов",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  name: "Accusamus,ml;m;.",
                  subseries: [],
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamus.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 78,
          name: "Accusamus.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porro.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamus.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamusvevebrbbbebe.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamuslpopnooj.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 8989,
          name: "Accusamuslnolooboo.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porrocevev.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "AccusamusvevXZ.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamusl,;wcwcff.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamuscwcwxfwfw.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 6,
          name: "Accusamus dwwfw ww.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porro wdwfw csdcsw",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamus cwvwvwv.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamuszdfv.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamusnknobi.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 7,
          name: "Autem.",
          img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00cc33?text=a",
          subcategories: [
            {
              id: 2,
              name: "Officiis.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/001122?text=cum",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusgegeageg.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamusfvxvx4hea.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamusgwgaaaa.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 8,
          name: "Nemo a.",
          img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/008844?text=et",
          subcategories: [
            {
              id: 201,
              subseries: [],
              name: "Aut alias1.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dddd?text=nemo",
              series: [
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamusgeehehe.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus.egegege",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            },
            {
              id: 202,
              name: "Aut alias2.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dddd?text=nemo",
              series: [
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamusfgegeg.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            },
            {
              id: 203,
              name: "Aut alias3.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dddd?text=nemo",
              series: []
            }
          ]
        },
        {
          id: 14,
          name: "Ut aut.",
          subcategories: [
            {
              id: 9,
              name: "Quisquam.",
              series: [
                {
                  id: 4,
                  name: "Sequi at.",
                  subseries: [],
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/0099bb?text=aut"
                }
              ],
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/004477?text=quia"
            }
          ],
          img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00ee11?text=ut"
        },
        {
          id: 27,
          name: "Minus.",
          subcategories: [
            {
              id: 20,
              name: "Iusto sit.",
              series: [
                {
                  id: 13,
                  name: "Ut iusto.",
                  subseries: [],
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/0000bb?text=maiores"
                }
              ],
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/0088dd?text=rerum"
            }
          ],
          img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00ffaa?text=excepturi"
        },
        {
          id: 799,
          name: "Accusamuscsvdge.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porroh5jtj.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusbrtj5rhr.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamusg54wj67k.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamusre hh45ju5.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 5656,
          name: "Accusamusjnikknknk.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porrofegq hq.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusvew gh e.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamusvsdvse 45yh.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamuscsvwsvs.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 4545,
          name: "Accusamuszefgegvdvdv.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porrovebebex.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamus.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamus vek jekb.eas.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus.;cs,;,;.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 158,
          name: "Accusamusmepmoegpe.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porrosgegege.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusvrbrbr.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamus ,re bk,rkbr.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus .vr ,b r,b,r.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 159,
          name: "Accusamusmvrme;lbmerl;bl;r;b.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: " verbnolelnbklenblknenblr.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamus, verllkklgelklge.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "mel;wmgp;wwf.w.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus e fkoewojwioe.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 149,
          name: "Accusamusm [wprp3.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porrokpkf[kewg.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusl;mvel;rgloe;g.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamuskm;lpfw;pgwe;lg.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus,knero pkww.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 147,
          name: "Accusamusnv nkgnel.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porro vk; ergkgn.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusknvlenolge.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "Accusamusknlkbgvkerkj.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamus.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
        {
          id: 145,
          name: "Accusamusf q4y43y.",
          img: "http://taro.a-lux.dev/storage/categories/May2022/WMZr5t5Cx3tO9e8D8xIZ.png",
          subcategories: [
            {
              id: 1,
              name: "Porro.",
              img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00dd99?text=quis",
              series: [
                {
                  id: 100,
                  subseries: [],
                  name: "Accusamusfe q3g4.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 101,
                  subseries: [],
                  name: "AccusamusFfwxf.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                },
                {
                  id: 102,
                  subseries: [],
                  name: "Accusamusevee.",
                  img: "http://taro.a-lux.dev/storage/https://via.placeholder.com/640x480.png/00bb99?text=doloremque"
                }
              ]
            }
          ]
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'toggleDesktopSidebar',
    ]),
  },
  methods: {
    closeSubcategoryList() {
      this.subcategoriesVisible = false;
    },
    toggleMenuItem(e, categoryId, categoryTitle, subcategoriesList) {
      if (categoryTitle !== this.categoryTitle) {
        this.categoryId = categoryId;
        this.categoryTitle = categoryTitle;
        this.activeMenuItem = categoryTitle;
        this.subcategoriesList = subcategoriesList;
        this.subcategoriesVisible = true;
      } else {
        this.subcategoriesVisible = !this.subcategoriesVisible;
      }
    },

    closeSidebar(e) {
      if (this.subcategoriesVisible &&!this.$refs.subcategorisList.$el.contains(e.target) &&  !this.$refs.sidebarAside.contains(e.target)) {
        this.subcategoriesVisible = false;
        this.activeMenuItem = '';
      }
      if(!this.$refs.sidebarAside.contains(e.target)) {
        this.activeMenuItem = '';
      }
    },
    hideSidebar(categoryTitle) {
      this.subcategoriesVisible = false;
      this.activeMenuItem = categoryTitle;
    },
  },
  mounted() {
    this.currenSelectedCategoriesItem =  document.querySelector(".sidebar")
    this.currenSelectedSubcategoriesItem =  document.querySelector(".sidebar")
    this.test =  document.querySelector(".sidebar")
    document.addEventListener('click', this.closeSidebar)
  }
}
</script>

<style scoped lang="scss">
.side {
  position: sticky;
  top: 119px;
  z-index: 99;
}

.sidebar__wrap {
  position: relative;
  //width: 300px;
  width: 243px;
  //margin-right: 43px;
  height: 513px;
  @include medium-tablet {
    display: none;
  }
}
.sidebar {
  position: absolute;
  //min-height: 513px;
  //top: 68px;
  //right: 0;
  //left: 0;
  z-index: 99;
  width: auto;
  //height: 513px;
  height: 100%;
  //padding-top: 28px;
  font-size: 16px;
  //background-color: $main-bg;
  direction: rtl;
  //overflow-y: scroll;
  //overflow-x: visible;
  & > ul {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    //max-height: 300px;
    height: 100%;
    //padding-top: 10px;
    max-height: 513px;
    overflow-y: scroll;
    scrollbar-width: thin;
    overflow-x: visible;
    width: 100%;
    //transform: scaleX(-1);
    //direction: ltr;
  }
  &__inner {
    position: relative;
    direction: ltr;
  }
  &__arrow {
    height: 18px;
    margin-left: 10px;
    //margin-left: 15px;
    & path {
      stroke: white;
    }

  }

  &__record {
    height: 34px;
    padding: 8px 24px;
    margin-top: 25px;
    margin-bottom: 100px;
    margin-left: 11px;
    color: $black;
    background-color: #FFE9C6;
    border-radius: 100px;
  }
  &__icon {
    width: 17px;
    height: 17px;
    margin-right: 10px;
    margin-left: 10px;
    overflow: hidden;
  }
  &__contact {
    display: flex;
    align-items: center;
    margin-left: 11px;
    margin-top: 60px;
    a {
      font-size: 16px;
      margin-left: 10px;
    }
  }
}

.categories {
  display: inline-block;

  &__item {
    position: relative;
    //width: 150px;
    padding-right: 16px;
    border-radius: 10px;
    margin-left: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 10px;
    //&:first-child {
    //  padding-top: 0;
    //}
  }
  &__item--active {
    background-color: white;
    color: $main-bg;
    & svg {
      & path {
        stroke: black;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    //padding: 11px;
    //padding-top: 16px;
    //padding-bottom: 16px;
  }
  &__link {
    display: flex;
    align-items: center;
    text-align: left;
    width: 134px;
    & > a {
      display: flex;
      align-items: center;
      width: 100%;
      & > span {
        width: 100%;
        line-height: 21px;
      }
    }
  }
}

.subcategories-top {
  top: 0;
}

.subcategories {
  position: absolute;
  top: -9px;
  left: -103px;
  //left: 100px;
  width: auto;
  min-width: 240px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-left: 300px;
  background: #FFFFFF;
  border-radius: 20px;
  color: black;
  &__item {
    position: relative;
    display: inline-block;
    font-size: 15px;
    //width: 300px;
    //padding: 16px 0;
    margin-bottom: 1px;
    border-bottom: 1px solid #EAEAEA;
    overflow-y: visible;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  &__inner {
    position: relative;
    //padding: 11px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    //width: 170px;
  }
  &__link {
    display: flex;
    align-items: center;
    text-align: left;
  }
  &__link--name {
    direction: ltr;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.series {
  position: absolute;
  top: 0px;
  left: -85px;
  //left: 100px;
  width: 240px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-left: 300px;
  background: #FFFFFF;
  border-radius: 20px;
  color: black;
  &__item {
    display: inline-block;
    font-size: 15px;
    //padding: 11px;
    //width: 300px;
    border-bottom: 1px solid #EAEAEA;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    //padding-top: 16px;
    //padding-bottom: 16px;
  }
  &__link {
    display: flex;
    align-items: center;
  }
}


</style>

<template>
  <div v-show="showFeedbackModal" ref="feedbackModal" class="form__overlay" @click.stop="closeFrom">
    <div class="form__wrapper">
      <form  v-show="isFormDefaultVisible" class="login__form form"  @submit.prevent="sendFeedbackModal">
        <button class="form__close">
          <img src="@/assets/image/sidebarIcons/hide-sidebar.svg" ref="closeFeedbackModal" @click.stop="closeFrom">
        </button>
        <h3 class="form__title">{{formTitle}}</h3>
        <label class="form__label" for="name-input">Имя</label>
        <input
          class="form__input"
          id="name-input"
          type="text"
          v-model.trim="formData.name"
          placeholder="Михаил"
        />
        <p
          v-if="$v.formData.name.$dirty && !$v.formData.name.required"
          class="form__feedback"
        >Обязательное поле
        </p>
        <label class="form__label form__label-email" for="email-input">Электронная почта</label>
        <input
          class="form__input"
          id="email-input"
          type="email"
          v-model.trim="formData.email"
          placeholder="Почта"
          @change="responseErrorMessage= ''"
          autocomplete="username"
        />
        <p
          v-if="$v.formData.email.$dirty && !$v.formData.email.required"
          class="form__feedback"
        >Обязательное поле
        </p>
        <p
          v-if="$v.formData.email.$dirty && !$v.formData.email.email"
          class="form__feedback"
        >Почта введена некорректно
        </p>
        <div v-show="inputForPhone">
          <label class="form__label" for="phone-input">Телефон</label>
          <input
            id="phone-input"
            class="form__input"
            type="tel"
            placeholder="7 (999) 999-99-99"
            v-mask="'#(###)###-##-##'"
            v-model.trim="formData.phone_number"
          />
          <p
            v-if="$v.formData.phone_number.$dirty && !$v.formData.phone_number.required"
            class="form__feedback"
          >Обязательное поле
          </p>
          <p
            v-if="$v.formData.phone_number.$dirty && !$v.formData.phone_number.minLength"
            class="form__feedback"
          >Номер введен не полностью
          </p>
        </div>

        <div v-show="inputForQuestion">
          <label class="form__label" for="question-input">Ваш вопрос</label>
          <input
            class="form__input"
            id="question-input"
            type="text"
            placeholder="Введите ваш вопрос..."
            v-model="isQuestion"
          />
<!--          <p-->
<!--            v-if="$v.isQuestion.$dirty && !$v.isQuestion.required"-->
<!--            class="form__feedback"-->
<!--          >Обязательное поле-->
<!--          </p>-->
        </div>
        <div class="form__group-line">
          <button class="form__button">Отправить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {api} from '@/api/api'
import { validationMixin } from 'vuelidate';
import {required, minLength, email, maxLength} from 'vuelidate/lib/validators';
import {mapMutations, mapGetters} from 'vuex';
import addFeedback from '@/mixins/addFeedback'

export default {
  name: 'FeedbackModal',
  mixins: [validationMixin, addFeedback],
  props: {
  },
  data() {
    return {
      formData: {
        question: this.formTitle,
        email: '',
        phone_number: '',
        name: '',
      },
      isQuestion: '',
      formDataForRecovery: {
        email: ''
      },
      responseMessage: '',
      responseErrorMessage: '',
      isFormForgotPasswordVisible: false,
      isFormDefaultVisible: true,
    }
  },
  computed: {
    ...mapGetters([
      'showFeedbackModal',
      'formTitle',
      'inputForQuestion',
      'inputForPhone',
      'productIdForNotificationOfArrival',
    ]),
  },
  methods: {
    ...mapMutations([
      'HIDE_FEEDBACK_MODAL'
    ]),
    closeFrom(e) {
      if(e.target === this.$refs.feedbackModal || e.target === this.$refs.closeFeedbackModal) {
        this.$store.commit('HIDE_FEEDBACK_MODAL')
        this.isFormForgotPasswordVisible = false;
        this.isFormDefaultVisible = true;
        this.formData.phone_number = '';
        this.formData.email = '';
        this.formData.name = '';
        this.isQuestion = '';
        this.formData.question = this.formTitle;
      }
    },
    sendFeedbackModal() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error) {
        this.isQuestion ? this.formData.question = this.isQuestion :  this.formData.question = this.formTitle;
        if (this.formTitle === 'Уведомить о поступлении') {
          this.formData.question = `Уведомить о поступлении товара с id ${this.productIdForNotificationOfArrival}`
        }
        this.addFeedback(this.formData)
        .then(res => {
          this.formData.email = '';
          this.formData.phone_number = '';
          this.formData.name = '';
          this.formData.question = this.formTitle;
          this.isQuestion = '';
        })
      }
    },
    clickForgotPassword() {
      this.isFormForgotPasswordVisible = true;
      this.isFormDefaultVisible = false;
    },
  },
  validations: {
    formData: {
      name: {
        required
      },
      email: {
        required,
        email,
      },
      phone_number: {
        required,
        minLength: minLength(15),
      },
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    //height: 100%;
    //width: 100%;
    background-color: rgba(51, 51, 51, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    width: 430px;
    padding: 50px;
    border-radius: 10px;
    background-color: $main-dark;
    position: relative;
    //@include phone {
    //  width: 100%;
    //  height: 100%;
    //}
  }
  &__close {
    position: absolute;
    left: 95%;
    z-index: 10001;
    & > img {
      width: 16px;
      height: 17px;
    }
  }
  &__error {
    font-size: 12px;
    color: #f8411e;
  }
  &__response {
    font-size: 12px;
    color: #43BC76;
  }
}
.form {
  position: relative;
  z-index: 200;
  background-color: $main-dark;
  display: flex;
  flex-direction: column;
  //@include phone {
  //  margin-top: 40%;
  //}
  &__input {
    width: 330px;
    height: 50px;
    background-color: $main-bg;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  &__input-email {
    margin-bottom: 20px;
  }
  &__feedback {
    font-size: 13px;
    margin-top: 5px;
    color: $orange-color;
  }
  &__label {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  }
  &__info {
    color: $grey-line;
    margin-bottom: 20px;
  }
  &__title {
    font-weight: 400;
    font-size: 25px;
    line-height: 22px;
    margin-bottom: 20px;
    white-space: nowrap;
  }
  &__button {
    width: 178px;
    padding: 14px 40px;
    background-color: $orange-color;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #fc9c4c;
    }
  }
  &__group-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
    line-height: 20px;
  }
  &__link {
    display: inline-block;
    text-decoration: underline;
    padding-right: 10px;
    color: white;
  }
  &__checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    margin-right: 14px;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;

    &:after {
      content: '\2714';
      font-weight: bold;
      display: none;
    }

    &:checked {
      &:after {
        display: inline;
      }
    }
  }
}

</style>

import { api, fetchWithAuth } from "@/api/api";

export default {
  state: {
    cart: [],
    // totalPrice: "",
    notification: "",
    totalPriceWithPromocode: "",
  },
  getters: {
    cart(state) {
      return state.cart;
    },
    notification(state) {
      return state.notification;
    },
    totalPrice(state, getters, rootState) {
      const total = state.cart.reduce(
        (acc, p) => acc + p.good.price * p.quantity,
        0
      );
      
      if(rootState.auth.isLoggedIn){
        return total * 0.85;
      }

      return total
    },
    totalPriceWithPromocode(state) {
      return state.totalPriceWithPromocode;
    },
  },

  mutations: {
    SET_PRODUCTS_CART: (state, products) => {
      state.cart = products;
    },
    SET_TO_CART: (state, product) => {
      state.cart.push(product);
    },
    REMOVE_FROM_CART: (state, id) => {
      state.cart = state.cart.filter((product) => product.good.id !== id);
    },
    SET_MESSAGE_TO_NOTIFICATION: (state, message, timeOut) => {
      state.notification = message;
    },
    REMOVE_MESSAGE_FROM_NOTIFICATION: (state) => {
      state.notification = "";
    },
    SET_TOTAL_PRICE: (state, totalPrice) => {
      state.totalPrice = totalPrice;
    },
    SET_TOTAL_PRICE_WITH_PROMOCODE: (state, totalPrice) => {
      state.totalPrice = totalPrice;
    },
    REDUCE_IN_CART: (state, id) => {
      state.cart.forEach((el) => {
        if (el.good.id === id) el.quantity -= 1;
      });
    },
    INC_TO_CART: (state, { id, quantity }) => {
      state.cart.forEach((el) => {
        if (el.good.id === id) el.quantity += quantity;
      });
    },
  },

  actions: {
    GET_TOTAL_PRICE({ commit, state }) {
      if (localStorage.getItem("access_token")) {
        return fetchWithAuth
          .get("api/cart/get-total")
          .then((totalPrice) => {
            commit("SET_TOTAL_PRICE", totalPrice.data);
            commit("SET_TOTAL_PRICE_WITH_PROMOCODE", totalPrice.data);
            return totalPrice;
          })
          .catch((error) => {
            return error;
          });
      }
      if (localStorage.getItem("_basket")) {
        let totalPriceLocalCart = 0;
        state.cart.forEach((product) => {
          totalPriceLocalCart += product.good.price * product.quantity;
        });
        commit("SET_TOTAL_PRICE", totalPriceLocalCart);
        commit("SET_TOTAL_PRICE_WITH_PROMOCODE", totalPriceLocalCart);
      }
    },
    async GET_PRODUCTS_CART({ commit, dispatch }) {
      if (localStorage.getItem("access_token")) {
        if (localStorage.getItem("_basket")) {
          JSON.parse(localStorage.getItem("_basket")).forEach((product) => {
            fetchWithAuth
              .post(
                `api/catalogue/${product.id}/add-to-cart?quantity=${product.quantity}`
              )
              .catch((err) => console.log("err in GET_PRODUCTS_CART  ", err));
          });
          localStorage.removeItem("_basket");
        }
        return fetchWithAuth
          .get("api/cart")
          .then((products) => {
            dispatch("GET_TOTAL_PRICE");
            commit("SET_PRODUCTS_CART", products.data.data);
            return products;
          })
          .catch((error) => {
            return error;
          });
      } else {
        if (localStorage.getItem("_basket")) {
          let localCart = [];
          JSON.parse(localStorage.getItem("_basket")).forEach((product) => {
            api.get(`api/catalogue/${product.id}`).then((res) => {
              localCart.push({
                good: res.data.data,
                quantity: product.quantity,
              });
              commit("SET_PRODUCTS_CART", localCart);
              dispatch("GET_TOTAL_PRICE");
            });
          });
        } else {
          commit("SET_PRODUCTS_CART", []);
          commit("SET_TOTAL_PRICE", 0);
          commit("SET_TOTAL_PRICE_WITH_PROMOCODE", 0);
        }
      }
    },

    ADD_TO_NOTIFICATION({ commit }, { message, timeOut = 2000 }) {
      commit("SET_MESSAGE_TO_NOTIFICATION", message);
      setTimeout(() => {
        commit("REMOVE_MESSAGE_FROM_NOTIFICATION");
      }, timeOut);
    },

    ADD_PRODUCT_TO_CART(
      { commit, state, dispatch },
      { product, quantity = 1 }
    ) {
      if (localStorage.getItem("access_token")) {
        fetchWithAuth
          .post(`api/catalogue/${product.id}/add-to-cart?quantity=${quantity}`)
          .then((response) => {
            dispatch("GET_PRODUCTS_CART");
            dispatch("ADD_TO_NOTIFICATION", { message: response.data.message });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let localCart = localStorage.getItem("_basket");
        if (localCart) {
          localCart = JSON.parse(localCart);
          let existingProduct = localCart.find((el) => el.id === product.id);
          if (existingProduct) {
            localCart.forEach((el) => {
              if (el.id === product.id) {
                el.quantity += quantity;
              }
            });
            localStorage.setItem("_basket", JSON.stringify(localCart));
            commit("INC_TO_CART", { id: product.id, quantity: quantity });
            dispatch("ADD_TO_NOTIFICATION", {
              message: "Товар успешно добавлен в корзину",
            });
          } else {
            localCart.push({ id: product.id, quantity: quantity });
            localStorage.setItem("_basket", JSON.stringify(localCart));
            commit("SET_TO_CART", { good: product, quantity: quantity });
            dispatch("ADD_TO_NOTIFICATION", {
              message: "Товар успешно добавлен в корзину",
            });
          }
        } else {
          localStorage.setItem(
            "_basket",
            JSON.stringify([{ id: product.id, quantity: quantity }])
          );
          commit("SET_TO_CART", { good: product, quantity: quantity });
          dispatch("ADD_TO_NOTIFICATION", {
            message: "Товар успешно добавлен в корзину",
          });
        }
      }
      dispatch("GET_TOTAL_PRICE");
    },

    REDUCE_PRODUCT_IN_CART({ commit, state, dispatch }, id) {
      if (localStorage.getItem("access_token")) {
        return fetchWithAuth
          .post(`api/catalogue/${id}/reduce-in-cart?quantity=1`)
          .then((response) => {
            dispatch("GET_PRODUCTS_CART");
            dispatch("ADD_TO_NOTIFICATION", { message: response.data.message });
          })
          .catch((error) => {
            return error;
          });
      } else {
        let localCart = JSON.parse(localStorage.getItem("_basket"));
        localCart.forEach((product) => {
          if (product.id === id) product.quantity -= 1;
        });
        localStorage.setItem("_basket", JSON.stringify(localCart));
        commit("REDUCE_IN_CART", id);
        dispatch("ADD_TO_NOTIFICATION", {
          message: "Успешно уменьшили количество товара в корзине!",
        });
      }
      dispatch("GET_TOTAL_PRICE");
    },

    DELETE_FROM_CART({ commit, dispatch }, id) {
      if (localStorage.getItem("access_token")) {
        return fetchWithAuth
          .delete(`api/catalogue/${id}/delete-from-cart`)
          .then((response) => {
            dispatch("GET_PRODUCTS_CART");
            dispatch("ADD_TO_NOTIFICATION", { message: response.data.message });
          })
          .catch((error) => {
            return error;
          });
      } else {
        let localCart = JSON.parse(localStorage.getItem("_basket"));
        localCart = localCart.filter((product) => product.id !== id);
        localStorage.setItem("_basket", JSON.stringify(localCart));
        commit("REMOVE_FROM_CART", id);
        dispatch("ADD_TO_NOTIFICATION", { message: "Товар удален из корзины" });
      }
      dispatch("GET_TOTAL_PRICE");
    },
    DELETE_ALL_FROM_CART({ commit, dispatch }) {
      if (localStorage.getItem("access_token")) {
        return fetchWithAuth.delete(`api/cart`).then((response) => {
          dispatch("GET_PRODUCTS_CART");
        });
      } else {
        localStorage.setItem("_basket", JSON.stringify([]));
        commit("SET_PRODUCTS_CART", []);
      }
      dispatch("GET_TOTAL_PRICE");
    },
  },
};

<template>
  <div class="slider-block" v-show="contentSlider.length">
    <div ref="swiper" class="swiper">
      <h2 class="slider-block__title">{{titleSlider}}</h2>
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="product in contentSlider" :key="product.id">
          <div class="slider-block__card">
            <router-link :to="`/catalogue/${product.id}`">
              <img
                class="slider-block__image image-1"
                :src="product.photos ? product.photos[0] : require('@/assets/image/not-found.jpg')"
                :alt="product.category"
              >
              <img
                class="slider-block__image image-2"
                :src="product.photos ? product.photos[1] : require('@/assets/image/not-found.jpg')"
                :alt="product.category"
              >
            </router-link>
            <div class="slider-block__info">
              <router-link :to="`/catalogue/${product.id}`">
                <div class="slider-block__name">{{ product.name }}</div>
                <div class="slider-block__descr">{{ product.description}}</div>
              </router-link>
              <div class="slider-block__price">
                {{ !profileData.is_wholesale ? formatPrice(product.price) : formatPrice(product.wholesale_price) }} тг
              </div>
              <div class="slider-block__buttons">
                <router-link :to="`/catalogue/${product.id}`">
                  <button class="slider-block__btn-more">Подробнее</button>
                </router-link>
                <button
                  class="slider-block__btn slider-block__btn-cart"
                  @click="ADD_PRODUCT_TO_CART({product: product})"
                >
                  <svg width="20" height="17" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0004 0.807129L12.2749 4.74688H14.6995V6.16355H13.8729L13.3367 12.5973C13.3219 12.7743
                13.2412 12.9393 13.1106 13.0597C12.9799 13.18 12.8088 13.2468 12.6312 13.2469H2.60117C2.42355 13.2468
                2.25245 13.18 2.12179 13.0597C1.99112 12.9393 1.91041 12.7743 1.89567 12.5973L1.35875
                6.16355H0.532837V4.74688H2.95675L5.23192 0.807129L6.45875 1.51546L4.593 4.74688H10.6386L8.77359
                1.51546L10.0004 0.807129ZM12.4513 6.16355H2.78038L3.25284 11.8302H11.9788L12.4513 6.16355ZM8.3245
                7.58021V10.4135H6.90784V7.58021H8.3245ZM5.49117 7.58021V10.4135H4.0745V7.58021H5.49117ZM11.1578
                7.58021V10.4135H9.74117V7.58021H11.1578Z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev button__prev"></div>
      <div class="swiper-button-next button__next"></div>
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper'
import { mapGetters, mapActions } from 'vuex'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
  name: 'MainProductSlider',
  data() {
    return {
    }
  },
  props: {
    contentSlider: {
      type: Array,
      default() {
        return this.recommended;
      }
    },
    titleSlider: {
      type: String,
      default() {
        return '';
      }
    },
  },
  computed: {
    ...mapGetters([
      'recommended',
      'discounted',
      'superPrice',
      'specialOffer',
      'profileData',
    ]),
  },
  methods: {
    ...mapActions([
      'ADD_PRODUCT_TO_CART',
    ]),
    formatPrice(price) {
      return String(price).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1.');
    }
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation],
      loop: false,
      slidesPerView: 4,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        300: {
          slidesPerView: 2,
          spaceBetween: 5,
          slideToClickedSlide: true,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 0,
          slideToClickedSlide: true,
        },
        1100: {
          slidesPerView: 4,
          spaceBetween: 0,
          slideToClickedSlide: true,
        }
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },
}
</script>

<style scoped lang="scss">
.slider-block {
  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 473px;
    padding: 16px 20px;
    background-color: $main-dark;
    overflow: hidden;
    border-radius: 10px;
    &:hover .image-2 {
      display: initial;
    }
    &:hover .image-1 {
      display: none;
    }
    @include phone {
      width: 196px;
      height: 308px;
      padding: 15px;
    }
  }
  &__title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 55px;
    @include phone {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }
  &__image {
    margin: 0 auto 15px;
    width: 100%;
    height: 240px;
    border-radius: 10px;
    @include phone {
      width: 100%;
      height: 135px;
      margin: 0 auto 10px;
    }
  }
  &__info {
    height: 196px;
    overflow: hidden;
  }
  &__name {
    height: 40px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    overflow: hidden;
    @include phone {
      height: 18px;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  &__descr {
    height: 36px;
    line-height: 18.07px;
    margin-bottom: 15px;
    color: #B0B0B0;
    overflow: hidden;
    text-overflow: ellipsis;
    @include phone {
      height: 30px;
      font-size: 10px;
      margin-bottom: 10px;
    }
  }
  &__availability {
    font-size: 12px;
    margin-bottom: 15px;
    &--available {
    }
    &--not-available {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .notify {
        color: $orange-color;
      }
    }
  }
  &__price {
    margin-bottom: 15px;
    font-size: 18px;
    @include phone {
      margin-bottom: 10px;
      font-size: 16px
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__btn-group {
    display: flex;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    color: $orange-color;
    border: 1px solid $orange-color;
    border-radius: 50%;
  }
  &__btn-more {
    color: $orange-color;
    border: 1px solid $orange-color;
    border-radius: 10px;
    cursor: pointer;
    width: 120px;
    height: 30px;
    @include phone {
      width: 80px;
    }
  }
  &__btn-favorite {
    margin-right: 15px;
  }
  &__btn-cart {
    border: none;
    & svg {
      fill: $orange-color;
    }
  }
}
.image-2 {
  display: none;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  background-color: $main-bg;
}
.button__prev {
  color: white;
  margin: 0 -12px;
  font-size: 20px;
  @include tablet {
    margin: 0 -17px;
    top: 50%;
    left: 3%;
  }
  &::after {
    font-size: 30px;
    @include tablet {
      font-size: 20px;
    }
  }
}
.button__next {
  color: white;
  margin: 0 -12px;
  @include tablet {
    margin: 0 -17px;
    top: 50%;
    right: 3%;
  }
  &::after {
    font-size: 30px;
    @include tablet {
      font-size: 20px;
    }
  }
}
</style>

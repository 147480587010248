import { api } from "@/api/api";

export default {
  state: {
    toggleMobileSidebar: false,
    toggleDesktopSidebar: true,
    categories: [],
    searchValue: '',
  },

  getters: {
    toggleMobileSidebar(state) {
      return state.toggleMobileSidebar;
    },
    toggleDesktopSidebar(state) {
      return state.toggleDesktopSidebar;
    },
    categories(state) {
      return state.categories;
    },
    searchValue(state) {
      return state.searchValue;
    }
  },

  mutations: {
    TOGGLE_SIDEBAR: (state, condition) => {
      condition === 'desktop' ? state.toggleDesktopSidebar = !state.toggleDesktopSidebar : state.toggleMobileSidebar = !state.toggleMobileSidebar;
    },
    HIDE_MOBILE_SIDEBAR: (state) => {
      state.toggleMobileSidebar = false;
    },
    CATEGORIES_TO_STATE: (state, categories) => {
      state.categories = categories;
    },
  },

  actions: {
    GET_CATEGORIES({commit, state}) {
      return api.get('api/categories')
        .then(categories => {
          commit('CATEGORIES_TO_STATE', categories.data.data);
          return categories;
        })
    },
  }
}

<template>
  <div class="catalog-item">
    <router-link :to="`/catalogue/${product_data.id}`">
      <img
        class="catalog-item__image image-1"
        :src="product_data.photos ? product_data.photos[0] :
        require('/src/assets/image/product1.png')"
        :alt="product_data.category"
      >
      <img
        class="catalog-item__image image-2"
        :src="product_data.photos ? product_data.photos[1] :
        require('/src/assets/image/product2.png')"
        :alt="product_data.category"
      >
      <div class="catalog-item__name">{{ product_data.name}}</div>
      <div class="catalog-item__descr">{{ product_data.description}}</div>
    </router-link>
    <div class="catalog-item__availability">
      <div
        class="catalog-item__availability--available"
        v-if="product_data.in_stock"
      >
        <img src="@/assets/image/icons/product-check.svg" alt="product available">
        <span>В Наличии</span>
      </div>
      <div
        class="catalog-item__availability--not-available" v-else>
        <div class="message">Нет в наличии</div>
        <div class="notify" @click="clickNotify(product_data.id)">Уведомить о поступлении</div>
      </div>
    </div>
    <div class="catalog-item__data">
      <div class="catalog-item__data-price">
        {{ formatPrice(product_data.price) }} тг
      </div>
      <div class="catalog-item__data-rating">
        <span>{{product_data.rating}}</span>
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.14168 0.452219L5.18879 3.81352L0.819452 4.35428C0.035902 4.45075 -0.278116 5.27076 0.290107 5.74043L3.45122 8.35534L2.70356 12.0492C2.56898 12.7169 3.39739 13.217 4.09122 12.9048L8 11.1607L11.9088 12.9048C12.6026 13.2145 13.431 12.7169 13.2964 12.0492L12.5488 8.35534L15.7099 5.74043C16.2781 5.27076 15.9641 4.45075 15.1805 4.35428L10.8112 3.81352L8.85832 0.452219C8.50841 -0.146926 7.49458 -0.154542 7.14168 0.452219Z"/>
        </svg>
        <button class="catalog-item__icon-rating" >
          <img @click="showRatingNotification" ref="iconRating" src="@/assets/image/icons/rating-info.svg">
          <RatingNotification
            ref="ratingNote"
            v-show="ratingNotification"
            class="catalog-item__rating-notification"/>
        </button>
      </div>
    </div>
    <div class="catalog-item__buttons">
      <router-link :to="`/catalogue/${product_data.id}`">
        <button class="catalog-item__btn-more">Подробнее</button>
      </router-link>
      <div class="catalog-item__btn-group">
        <button
          class="catalog-item__btn catalog-item__btn-favorite"
          @click="addToFavorite(product_data.id)"
        >
          <svg width="12" height="11" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.881C9.981 0.729 11.484 0 13.05 0C15.822 0 18 2.178 18 4.95C18 8.34921 14.945 11.1195 10.3164 15.3167L10.305 15.327L9 16.515L7.695 15.336L7.65947 15.3037C3.04437 11.1098 0 8.3433 0 4.95C0 2.178 2.178 0 4.95 0C6.516 0 8.019 0.729 9 1.881ZM9 14.085L9.09 13.995C13.374 10.116 16.2 7.551 16.2 4.95C16.2 3.15 14.85 1.8 13.05 1.8C11.664 1.8 10.314 2.691 9.846 3.924H8.163C7.686 2.691 6.336 1.8 4.95 1.8C3.15 1.8 1.8 3.15 1.8 4.95C1.8 7.551 4.626 10.116 8.91 13.995L9 14.085Z"/>
          </svg>
        </button>
        <button
          :disabled="!product_data.in_stock"
          :class="{'catalog-item__btn': true, 'catalog-item__btn-cart': true, 'catalog-item__btn-cart--disabled': !product_data.in_stock }"
          @click="ADD_PRODUCT_TO_CART({product: product_data})"
        >
          <svg width="13" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.88421 0.435303L12.1587 4.37505H14.5833V5.79172H13.7567L13.2205 12.2255C13.2057 12.4025 13.125 12.5675 12.9943 12.6878C12.8637 12.8081 12.6926 12.875 12.515 12.8751H2.48496C2.30734 12.875 2.13624 12.8081 2.00557 12.6878C1.8749 12.5675 1.7942 12.4025 1.77946 12.2255L1.24254 5.79172H0.416626V4.37505H2.84054L5.11571 0.435303L6.34254 1.14364L4.47679 4.37505H10.5224L8.65738 1.14364L9.88421 0.435303ZM12.335 5.79172H2.66417L3.13663 11.4584H11.8626L12.335 5.79172ZM8.20829 7.20839V10.0417H6.79163V7.20839H8.20829ZM5.37496 7.20839V10.0417H3.95829V7.20839H5.37496ZM11.0416 7.20839V10.0417H9.62496V7.20839H11.0416Z" fill="#333333"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import formatPrice from '@/mixins/formatPrice'
import addToFavorite from "@/mixins/addToFavorite";
import RatingNotification from "@/components/common/RatingNotification";

export default {
  name: 'VCatalogItem',
  components: {
    RatingNotification,
  },
  props: {
    product_data: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mixins: [formatPrice, addToFavorite],
  data() {
    return {
      ratingNotification: false,
      name: String(this.product_data.id)
    }
  },
  computed: {
    ...mapGetters([
      'cart',
    ]),
  },
  methods: {
    ...mapActions([
      'GET_PRODUCTS_CART',
      'ADD_PRODUCT_TO_CART',
    ]),
    showRatingNotification() {
      this.ratingNotification = !this.ratingNotification;
    },
    hideRatingNotification(e) {
      if (e.target !== this.$refs.iconRating) {
        this.ratingNotification = false
      }
    },
    clickNotify(id) {
      this.$store.commit('SHOW_FEEDBACK_MODAL', {formTitle:'Уведомить о поступлении', inputForPhone:true, productId: id})
    }
  },
  mounted() {
    window.addEventListener('click', this.hideRatingNotification);
  },
  destroyed() {
    window.removeEventListener('click', this.hideRatingNotification);
  }
}
</script>

<style scoped lang="scss">
.catalog-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width:calc(25% - 20px);
  min-width: 165px;
  padding: 20px;
  background-color: $main-dark;
  margin-bottom: 40px;
  border-radius: 10px;
  margin-right: 20px;
  @include small-desktop {
    width:calc(33.333% - 21px);
  }
  @include tablet {
    justify-content: space-between;
    margin-right: 0;
    padding: 15px;
    width: 45%;
  }
  @include phone {
    justify-content: space-between;
    margin-right: 0;
    padding: 10px;
    width: 44%;
  }
  &:hover .image-2 {
    display: initial;
  }
  &:hover .image-1 {
    display: none;
  }
  &__image {
    margin: 0 auto 16px;
    width: 100%;
    height: 233px;
    border-radius: 5px;
    @include tablet {
      height: 219px;
    }
    @include phone {
      height: 135px;
    }
  }
  &__name {
    height: 36px;
    display: flex;
    font-size: 18px;
    margin-bottom: 16px;
    @include phone {
      line-height: 15px;
      height: 15px;
      font-size: 12px;
    }
  }
  &__descr {
    color: #B0B0B0;
    margin-bottom: 16px;
    height: 35px;
    overflow: hidden;
    //text-overflow: clip;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @include phone {
      height: 26px;
      font-size: 10px;
      line-height: 13px;
      margin-bottom: 7px;
    }
  }
  &__availability {
    font-size: 12px;
    margin-bottom: 20px;
    @include phone {
      font-size: 8px;
      margin-bottom: 10px;
    }
    &--available {
      & > img {
        margin-right: 8px;
      }
    }
    &--not-available {
      display: flex;
      //justify-content: space-between;
      //align-items: center;
      flex-direction: column;
      @include phone {
        flex-direction: column;
        align-items: stretch;
      }
      & .notify {
        color: $orange-color;
      }
    }
  }
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @include phone {
      margin-bottom: 10px;
    }
    &-price {
      font-size: 18px;
      @include phone {
        font-size: 14px;
      }
    }
    &-rating {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        margin-right: 5px;
        font-size: 13px;
        line-height: 13px;
      }
      & > img {
        width: 13px;
        height: 13px;
        padding: 0;
      }
      & > button {
        width: 13px;
        height: 13px;
        margin-left: 5px;
      }
    }
  }
  &__icon-rating{
    position: relative;
  }
  &__data-rating {
    & > svg {
      fill: #FFAB5D;
    }
    &:hover {
      & > svg {
        fill: #fc7c23;
      }
    }
  }
  &__rating-notification {
    position: absolute;
    right: -42px;
    top: 22px;
    @include phone {
      top: 29px;
      right: -22px;
    }

  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__btn-group {
    display: flex;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    color: $orange-color;
    border-radius: 50%;
    @include phone {
      margin-left: 5px;
      height: 20px;
      width: 20px;
    }
  }
  &__btn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    color: $orange-color;
    border: 1px solid $orange-color;
    border-radius: 10px;
    cursor: pointer;
    max-width: 120px;
    min-width: 100px;
    height: 36px;
    &:hover {
      border: 1px solid #fc7c23;
      color: #fc7c23;
    }
    @include phone {
      max-width: 100px;
      height: 26px;
      padding: 1px 8px;
    }
  }
  &__btn-cart {
    background-color: $orange-color;
    &:hover {
      background-color: $orange-btn-active;
    }
    &:active {
      background-color: #fc7c23;
    }
    & > svg {
      fill: black;
      @include phone {
        width: 10px;
        height: 9px;
      }
    }
    &--disabled {
      background-color: #9d7f63f2;
      &:active {
        background-color: #9d7f63f2;
      }
    }
  }
  &__btn-favorite {
    border: 1px solid $orange-color;
    margin-right: 15px;
    & > svg {
      fill: $orange-color;
      @include phone {
        width: 10px;
        height: 9px;
      }
    }
    &:hover {
      border: 1px solid #fc7c23;
      & > svg {
        fill: #fc7c23;
      }
    }
    @include phone {
      margin-right: 0px;
    }
  }
}
.image-2 {
  display: none;
}


</style>

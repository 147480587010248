<template>
  <div class="star-rating">
    <span v-for="i in starLimit" :key="i" class="star star--empty"></span>
    <div class="star-rating__colored" :style="ratingWidthStyle">
      <span v-for="i in starLimit" :key="i" class="star star--colored"></span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'StarRating',
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    starLimit: {
      type: Number,
      default: 5,
    }
  },
  computed: {
    ratingWidth() {
      return this.rating / this.starLimit * 100;
    },
    ratingWidthStyle() {
      return `width: ${this.ratingWidth}%`;
    },
  }
}
</script>

<style lang="scss" scoped>
.star-rating {
  position: relative;
  display: inline-flex;
  &__colored {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    display: inline-flex;
    overflow: hidden;
  }
}
.star {
  display: inline-block;
  width: 13.33px;
  height: 13.33px;
  margin: 0 3px;
  flex-shrink: 0;
  &--colored {
    background: url("/src/assets/image/icons/gold-star.svg") center no-repeat;
  }
  &--empty {
    background: url("/src/assets/image/icons/empty-star.svg") center no-repeat;
  }
}

</style>

